import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 500 500">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd" transform="">



<path fill="#FEFFFF" opacity="1.000000" stroke="none" 
	d="
M339.000000,501.000000 
	C226.000031,501.000000 113.500053,501.000000 1.000060,501.000000 
	C1.000040,334.333405 1.000040,167.666794 1.000020,1.000150 
	C167.666565,1.000100 334.333130,1.000100 500.999756,1.000050 
	C500.999847,167.666519 500.999847,334.333038 500.999939,500.999786 
	C447.166656,501.000000 393.333344,501.000000 339.000000,501.000000 
M320.000519,171.992126 
	C320.000519,171.992126 319.930969,171.968933 320.480774,171.647171 
	C320.624268,171.049942 320.767731,170.452728 320.964111,169.240509 
	C321.087738,168.848724 321.211365,168.456940 322.017883,167.840714 
	C324.975098,163.334427 324.536072,153.085190 321.097229,148.898544 
	C320.667999,148.375946 319.654419,148.333313 318.975891,147.989868 
	C318.975891,147.989868 318.927338,148.076965 318.984283,147.327484 
	C318.020691,143.036819 315.070435,141.290375 311.003967,141.006531 
	C311.003967,141.006531 310.955597,141.062790 310.908081,140.314911 
	C308.602905,139.223495 306.327972,138.055557 303.961151,137.120102 
	C303.590454,136.973602 302.802185,137.883652 302.207001,138.305161 
	C302.851013,138.818512 303.495026,139.331879 304.093903,139.893524 
	C304.093903,139.893524 304.158081,139.909302 304.060394,140.622147 
	C304.696747,141.419327 305.333069,142.216507 306.163361,143.316330 
	C306.163361,143.316330 306.207184,143.673111 306.079559,144.546661 
	C306.753815,147.371460 307.428070,150.196259 308.156616,153.686096 
	C308.106964,154.126831 308.057343,154.567551 307.265228,155.156021 
	C306.193054,158.785553 305.120911,162.415085 304.009949,166.003113 
	C304.009949,166.003113 304.055084,166.046738 303.351837,166.069489 
	C300.579803,169.734619 297.807800,173.399765 294.789825,177.580658 
	C294.217834,178.067245 293.645813,178.553848 292.329376,179.007187 
	C289.183228,180.684250 286.037079,182.361313 282.958466,183.098572 
	C282.973938,179.445267 283.001740,175.791977 283.002655,172.138672 
	C283.005951,158.791275 283.003174,158.917450 269.392609,158.981064 
	C265.472931,158.999374 263.990692,160.479568 264.018188,164.218628 
	C264.085358,173.351898 263.971039,182.487152 264.126129,191.618286 
	C264.145172,192.739304 265.361298,193.839966 265.722321,195.592300 
	C261.806610,197.395508 257.890900,199.198715 254.020599,200.065292 
	C254.020599,189.834549 254.020599,179.603806 254.020599,168.669632 
	C248.692703,168.669632 244.130112,168.648727 239.567810,168.676941 
	C236.522491,168.695801 235.883591,170.428665 235.916809,173.220154 
	C236.051163,184.511566 235.971008,195.805542 235.989944,207.859482 
	C235.965073,208.878891 235.940186,209.898285 235.121445,210.966248 
	C232.008087,211.635422 228.894714,212.304611 225.892090,212.046066 
	C225.892090,203.144958 225.892090,194.243866 225.892090,185.425293 
	C219.192261,185.425293 213.301529,185.425293 207.019257,185.425293 
	C207.019257,195.474106 206.972519,205.103058 207.107040,214.729477 
	C207.117920,215.508713 208.371521,216.270584 208.900238,217.758682 
	C203.852402,220.143204 198.944107,223.213074 193.774887,220.962646 
	C194.854324,219.791870 196.780167,218.679306 196.865372,217.440170 
	C197.216965,212.326691 197.169739,207.165817 196.878571,202.044556 
	C196.818283,200.984375 195.145477,199.220734 194.119247,199.148193 
	C189.667282,198.833511 185.173218,198.903015 180.710083,199.134109 
	C179.802200,199.181137 178.226196,200.509445 178.204895,201.281479 
	C178.009842,208.351379 178.090164,215.428864 178.090164,222.758148 
	C180.166077,222.854538 181.625763,222.922333 182.917313,223.710388 
	C180.615067,224.767136 178.312805,225.823883 175.346313,226.996170 
	C174.897598,226.998077 174.448868,226.999985 173.458847,226.435760 
	C163.746841,227.200500 154.034836,227.965240 144.322830,228.729980 
	C144.318039,229.365784 144.313232,230.001602 144.308441,230.637405 
	C150.046234,233.960953 156.347412,234.765106 163.651199,233.994354 
	C164.086609,234.061996 164.522003,234.129654 165.122055,234.845734 
	C166.421921,234.868301 167.721786,234.890854 169.820068,234.877533 
	C170.880722,234.958282 171.941360,235.039032 173.457413,235.607773 
	C175.327011,237.095505 177.282837,238.488037 179.050201,240.088638 
	C184.556686,245.075577 188.888107,251.680145 196.998047,252.997635 
	C196.998047,252.997635 197.031555,252.953079 197.121506,253.674545 
	C198.393723,254.130478 199.665939,254.586395 200.989975,254.997406 
	C200.989975,254.997406 200.966385,255.061783 201.057358,255.736679 
	C202.373779,256.152069 203.690186,256.567413 205.638245,256.932800 
	C206.100555,256.592377 206.562866,256.251953 207.033539,255.198929 
	C206.038025,254.127045 205.042496,253.055161 203.848373,251.686325 
	C203.848373,251.686325 203.791412,251.333649 203.911819,250.538284 
	C202.169983,246.727524 200.302628,242.966263 198.768112,239.073776 
	C198.345642,238.002151 198.916870,236.538788 199.032150,235.255173 
	C200.041779,235.871445 201.127136,236.393509 202.041260,237.128571 
	C203.110611,237.988464 204.030670,239.034027 205.176987,240.322601 
	C205.176987,240.322601 205.199585,240.683533 205.082565,241.470215 
	C206.107742,242.949387 207.132904,244.428543 208.590439,246.303711 
	C208.781174,246.852631 208.971924,247.401550 208.623337,248.295776 
	C209.778290,249.515488 210.933258,250.735214 212.607635,252.309906 
	C213.736267,253.543976 214.864899,254.778046 216.168518,256.471222 
	C216.359604,256.759827 216.624252,256.941071 217.000931,256.998779 
	C217.000931,256.998779 216.980820,257.035370 216.993439,257.792023 
	C218.982666,262.083649 224.024689,263.684570 227.062790,260.032166 
	C227.121017,251.350845 227.179245,242.669540 227.707977,233.535126 
	C229.003448,232.501526 230.298920,231.467926 231.594391,230.434311 
	C232.068451,231.909103 232.922806,233.376373 232.952133,234.859970 
	C233.095367,242.105545 232.915726,249.358139 233.104004,256.601654 
	C233.142746,258.093201 234.263794,259.556641 234.966232,261.017273 
	C234.966232,261.017273 234.971451,261.098877 235.093399,261.755249 
	C240.818878,263.031036 245.975784,260.068909 246.992569,255.004456 
	C246.992569,255.004456 246.937881,254.954239 247.614548,254.912537 
	C248.035034,254.228119 248.455521,253.543701 248.991730,252.980682 
	C248.991730,252.980682 248.856308,252.881790 249.590652,252.857239 
	C249.972733,252.089355 250.354797,251.321457 250.773514,250.246109 
	C250.773514,250.246109 250.960464,249.999268 251.444427,249.785583 
	C251.591660,249.477356 251.738892,249.169128 251.867233,248.975708 
	C251.867233,248.975708 251.838165,248.863037 252.580490,248.883636 
	C255.686844,245.753662 258.799408,242.626511 257.750824,237.257034 
	C257.750824,237.257034 257.928802,236.972244 258.665161,236.866028 
	C259.745667,232.884216 260.826141,228.902420 261.883728,224.958694 
	C261.883728,224.958694 261.880646,224.911148 262.556396,224.877686 
	C262.720764,220.302017 265.518616,217.936325 269.558746,216.759613 
	C271.119507,216.305023 272.785736,216.032974 274.409332,216.006256 
	C279.897034,215.915955 285.394104,216.121979 290.871368,215.866943 
	C292.212006,215.804504 293.490997,214.417175 294.882263,213.298401 
	C294.882263,213.298401 295.086517,213.013229 295.792877,212.868515 
	C296.178436,210.241653 296.563995,207.614777 296.935669,204.187088 
	C297.012238,203.122849 297.088806,202.058624 297.645966,200.566071 
	C298.416565,199.025513 299.187164,197.484970 300.006256,195.985672 
	C300.006256,195.985672 299.946259,195.966080 300.443237,195.803513 
	C300.618896,195.513611 300.794525,195.223694 301.152618,194.495209 
	C301.337952,194.222519 301.587219,194.034592 302.643066,193.975082 
	C306.740143,190.343506 311.201813,187.033325 314.795135,182.957611 
	C316.890198,180.581284 317.595551,176.979721 318.998291,173.990997 
	C318.998291,173.990997 318.911346,173.953262 319.460968,173.665695 
	C319.622528,173.091599 319.784088,172.517487 320.000519,171.992126 
M411.490631,327.381653 
	C411.388672,328.010162 411.286713,328.638702 411.179291,330.014130 
	C411.083954,331.004456 410.988617,331.994781 410.413635,333.379974 
	C410.317047,334.597778 410.220459,335.815613 410.076080,337.680939 
	C410.024048,338.110504 409.972046,338.540070 409.335510,339.078247 
	C409.062164,339.510010 408.788788,339.941742 408.515472,340.373505 
	C409.051941,340.555969 409.588440,340.738434 410.573395,341.010223 
	C410.573395,341.010223 411.011749,341.140503 411.501862,341.611542 
	C412.999146,341.474213 414.496460,341.336853 416.723785,341.104584 
	C417.458374,341.056183 418.192932,341.007812 419.589600,340.957489 
	C419.807281,340.922394 420.024994,340.887299 420.897125,340.958893 
	C421.653473,340.664764 422.409851,340.370636 423.775085,339.915405 
	C423.826721,338.940796 423.878357,337.966217 423.965118,336.347015 
	C424.015411,335.919800 424.065704,335.492554 424.784058,335.015015 
	C425.451782,335.046692 426.119537,335.078369 426.938354,335.947937 
	C427.087982,337.514862 427.237579,339.081818 427.335663,340.108978 
	C433.194946,343.865540 432.845917,336.289581 435.360840,335.989838 
	C437.233948,338.008850 438.600494,339.481842 440.740021,340.983246 
	C441.251190,340.909271 441.762390,340.835297 442.645874,340.891357 
	C442.645874,340.891357 442.995209,341.074371 443.090942,341.656097 
	C443.511536,341.937927 443.932098,342.219727 444.352722,342.501556 
	C444.547089,341.974579 444.741455,341.447601 444.992584,341.002319 
	C444.992584,341.002319 444.921661,340.931854 445.735107,340.977600 
	C446.818054,340.982025 447.901001,340.986481 449.663055,341.025452 
	C450.321289,340.874817 450.979492,340.724152 452.281067,341.014435 
	C452.610840,341.409851 452.940613,341.805298 453.176544,342.952911 
	C455.898193,346.729309 459.090729,345.333221 462.086304,343.551880 
	C464.263062,342.257477 466.174713,340.517273 467.833801,339.257355 
	C465.097015,338.498535 465.097015,338.498535 464.337555,331.069427 
	C461.961975,331.069427 459.535736,331.069427 456.615479,331.162262 
	C456.392578,331.402710 456.169708,331.643188 455.937531,331.939819 
	C455.937531,331.939819 455.886871,331.913818 455.075073,331.957550 
	C453.983490,332.027863 452.891937,332.098175 451.191620,331.695312 
	C448.810944,331.478760 446.430298,331.262207 443.344055,330.885925 
	C442.270416,329.998596 441.196777,329.111237 440.123108,328.223907 
	C439.748474,329.158356 439.373840,330.092804 438.187531,331.205505 
	C435.808990,331.860291 433.430450,332.515076 430.229004,333.396423 
	C429.734467,333.128998 427.941956,332.159790 425.565369,330.712860 
	C424.639374,329.367462 423.713348,328.022095 422.787354,326.676697 
	C422.222565,326.904907 421.657776,327.133118 421.092987,327.361328 
	C421.092987,328.944672 421.092987,330.527985 421.062653,332.821686 
	C420.981354,333.526703 420.900055,334.231689 420.118073,335.080566 
	C416.195251,337.690887 416.297028,333.560638 414.893829,331.154968 
	C414.893707,329.817780 414.893616,328.480591 414.868286,326.455963 
	C413.461823,324.405243 412.543579,324.728180 411.996582,327.007080 
	C411.996582,327.007080 412.040527,327.025330 411.490631,327.381653 
M38.260975,328.833710 
	C38.260975,328.833710 38.268349,328.775116 38.371075,328.162628 
	C37.817841,327.691498 37.264603,327.220367 36.711361,326.749237 
	C36.313210,327.505768 35.915054,328.262268 35.191036,329.766205 
	C34.562847,333.312408 33.934658,336.858612 33.155464,341.257263 
	C36.566898,341.056061 39.280174,340.896027 42.690887,340.510040 
	C44.329376,340.670654 46.017845,340.633392 47.596241,341.031982 
	C51.189968,341.939484 53.333298,341.197144 52.988609,337.005768 
	C52.988609,337.005768 52.987625,336.973328 53.794792,336.938599 
	C54.811806,337.431641 56.507378,337.717285 56.732018,338.452454 
	C57.896835,342.264313 59.931892,341.817169 62.366013,340.388458 
	C62.014950,340.106995 61.663891,339.825562 61.312832,339.544098 
	C62.142170,337.303864 62.971508,335.063599 64.560692,332.981201 
	C65.731026,334.997314 66.901367,337.013397 68.226646,339.769287 
	C70.320633,339.885620 73.599464,340.848816 74.252464,339.935181 
	C75.467957,338.234589 75.567154,335.383911 75.255966,333.132935 
	C75.145111,332.331055 72.494911,331.880219 70.745522,330.603943 
	C68.743393,326.525543 66.331795,324.552032 62.560825,328.923981 
	C62.346317,329.172668 61.604847,328.966827 60.762318,328.391663 
	C59.430710,329.279419 58.172798,330.320618 56.744263,331.006500 
	C55.594875,331.558411 54.239071,331.680389 52.820259,331.620728 
	C52.641121,331.367889 52.403572,331.287231 51.321335,331.128510 
	C47.967144,330.486237 45.869801,332.007751 44.283936,334.871185 
	C43.547115,336.201569 42.217575,337.203674 40.220867,338.124390 
	C39.586063,338.030396 38.951260,337.936401 38.414349,337.761200 
	C38.414349,337.761200 38.315235,337.831635 38.047741,336.876892 
	C38.023701,335.556335 37.999657,334.235748 38.367329,332.500305 
	C38.312763,331.273376 38.258194,330.046478 38.260975,328.833710 
M247.039429,142.966736 
	C247.039429,142.966736 247.069321,142.890396 246.931107,142.239792 
	C245.284332,141.852066 243.637573,141.464355 241.327499,141.067444 
	C240.891266,140.998322 240.455017,140.929199 239.639359,140.407608 
	C238.754807,140.507614 237.870239,140.607620 236.317871,140.792496 
	C235.869370,140.809265 235.420853,140.826035 234.701324,140.263885 
	C234.429749,139.465805 234.158173,138.667740 233.981094,137.143845 
	C235.573761,132.116837 237.911240,129.082825 244.259750,130.357178 
	C251.710037,131.852692 259.203857,130.480667 266.192108,127.313393 
	C267.678680,126.639633 268.846252,125.262039 271.073853,123.477097 
	C268.195984,123.731438 266.925385,124.057396 265.708038,123.918091 
	C254.184372,122.599342 242.680740,121.098877 231.148529,119.863754 
	C223.923569,119.089951 216.640747,118.058502 209.418121,118.276566 
	C201.589783,118.512932 194.696213,125.317520 194.107269,130.556534 
	C199.588760,129.140533 205.497879,127.102615 211.575821,126.193893 
	C217.181473,125.355774 218.529068,127.081924 216.477463,132.321381 
	C212.180222,143.295715 207.807816,154.289566 202.569336,164.829620 
	C198.941086,172.129852 193.813477,178.684875 189.355820,185.572876 
	C201.060257,179.789841 209.660446,170.598770 218.430023,160.728180 
	C218.875870,160.035324 219.321732,159.342468 219.871124,158.314087 
	C219.871124,158.314087 220.063644,158.020447 220.515121,157.807312 
	C220.796341,157.588486 220.948395,157.301254 221.231033,156.440384 
	C221.558838,155.976288 221.886642,155.512177 222.610870,154.845810 
	C222.856384,154.650421 223.000351,154.397263 223.240555,153.636063 
	C223.414856,153.345856 223.654907,153.128143 224.463470,152.765259 
	C224.592850,152.436890 224.722214,152.108521 224.915344,151.394211 
	C224.915344,151.394211 225.049957,151.026917 225.828308,150.994354 
	C226.606873,150.953232 227.385452,150.912094 228.975983,150.947037 
	C238.599503,151.703934 240.009140,152.702820 240.987976,159.458771 
	C247.995056,156.664139 251.475891,148.876099 247.835358,143.295166 
	C247.636139,143.092468 247.395737,142.971741 247.039429,142.966736 
M247.702057,337.773254 
	C247.902008,338.782806 248.101959,339.792358 248.301910,340.801910 
	C249.080475,340.525482 249.859039,340.249054 250.879181,339.241058 
	C250.960068,338.220215 251.040955,337.199371 251.753281,335.814026 
	C252.856705,335.533630 253.960144,335.253235 255.078552,335.848480 
	C255.078552,337.109497 255.078552,338.370483 255.078552,339.902832 
	C259.316559,340.104370 263.151245,340.286713 267.576508,340.258392 
	C268.559875,337.173004 272.624542,333.884521 266.220062,331.028412 
	C264.840057,331.496307 263.460022,331.964233 261.387878,332.564270 
	C260.252472,332.817505 259.117096,333.070740 257.652161,333.014160 
	C257.652161,333.014160 257.226440,332.861481 256.889191,332.219116 
	C255.763397,331.595154 254.450867,331.169006 253.544083,330.313019 
	C249.575378,326.566620 242.983826,325.606415 238.209732,327.928497 
	C238.908844,330.096588 239.763733,332.131805 240.194061,334.253143 
	C240.636902,336.436157 240.665710,338.703125 240.875717,340.933380 
	C242.061554,338.664459 243.843903,336.504333 244.282608,334.099152 
	C244.834106,331.075623 245.723648,329.442841 248.924088,330.749023 
	C248.967270,331.491486 249.010452,332.233917 248.811539,333.618256 
	C248.643753,334.720642 248.475952,335.823059 248.130951,337.103271 
	C248.130951,337.103271 248.120850,337.354095 247.702057,337.773254 
M345.491425,328.250031 
	C345.334656,328.422424 345.177917,328.594849 344.411224,328.852081 
	C340.687683,332.620178 340.114227,336.191284 342.975220,339.131042 
	C346.228546,342.473938 349.583618,342.234985 353.142670,338.470734 
	C354.073914,337.840881 355.005127,337.211029 356.126770,337.251312 
	C357.551605,338.159302 357.054321,343.381042 359.804596,340.184265 
	C362.588654,336.948273 363.323181,331.948944 364.955444,327.721985 
	C364.511932,327.280487 364.068390,326.838989 363.624847,326.397461 
	C361.168457,327.794495 358.712097,329.191528 356.706573,330.332153 
	C354.494202,329.271729 352.265411,328.203430 349.329498,327.037506 
	C348.863556,327.116028 348.397614,327.194550 347.409607,327.193695 
	C346.993103,327.477875 346.576569,327.762085 345.491425,328.250031 
M115.954674,340.673584 
	C115.954674,340.673584 116.173492,340.287811 116.995102,340.458893 
	C118.591499,340.634338 120.187897,340.809784 121.839455,340.991302 
	C122.575539,339.219971 123.221817,337.664764 124.229836,335.239075 
	C124.998016,337.057220 125.397781,338.003357 125.432999,339.502075 
	C128.735336,340.170288 132.024048,340.924805 135.349121,341.448761 
	C136.231873,341.587891 137.679153,341.311279 138.067917,340.709290 
	C138.723526,339.694153 138.740524,338.266541 139.571365,336.878693 
	C139.699707,336.742462 139.828064,336.606262 140.732635,336.539398 
	C142.926804,339.600677 144.960327,342.999542 150.590759,340.921906 
	C151.825256,340.894714 153.059753,340.867523 154.923767,340.923859 
	C156.244354,340.826569 157.564957,340.729309 160.314667,340.526764 
	C156.330612,336.640900 157.869553,334.142761 160.762939,331.947479 
	C158.987518,330.291260 157.531143,328.932648 156.016846,327.520020 
	C154.798157,329.284180 153.866486,330.632843 152.371933,332.017242 
	C152.185806,332.043701 151.999680,332.070190 151.258911,331.728638 
	C148.976334,331.758972 146.676559,331.630493 144.417191,331.875183 
	C142.898224,332.039703 141.436447,332.732239 139.741486,332.565186 
	C139.601044,332.397156 139.460602,332.229126 139.285873,331.620544 
	C138.882889,331.530945 138.479919,331.441345 137.282059,331.109161 
	C136.207474,331.252045 135.132874,331.394928 133.486313,331.413300 
	C133.013458,331.953827 132.540604,332.494324 131.580612,333.323334 
	C131.406525,333.651855 131.232437,333.980377 130.287491,334.203674 
	C129.735321,333.437592 129.196472,332.017700 128.628906,332.006134 
	C122.625748,331.883667 116.618004,331.985474 110.148842,332.722443 
	C110.339752,333.212982 110.530663,333.703552 109.883415,334.271851 
	C109.035767,333.853607 108.220604,333.341003 107.334023,333.035828 
	C105.167313,332.289886 102.443802,330.523315 100.916542,331.239807 
	C99.094643,332.094482 98.286308,335.109680 96.274345,337.354492 
	C94.702446,337.238312 93.091576,337.296692 91.569565,336.956970 
	C90.039062,336.615387 88.596985,335.877594 87.116135,335.313446 
	C88.331856,333.787598 89.285469,331.841064 90.846069,330.868774 
	C92.098915,330.088257 94.087555,330.488831 96.359978,330.319916 
	C93.847313,325.790527 90.482178,326.409668 87.809891,327.661682 
	C84.030663,329.432343 82.199341,332.905914 83.364189,337.176483 
	C84.298828,340.602997 86.910004,341.964508 90.306107,340.980743 
	C92.624321,340.309204 94.787773,339.103363 97.240814,338.916626 
	C101.791008,343.152557 109.108734,340.635345 111.496368,334.811493 
	C112.316406,335.664276 113.318840,336.408783 113.906975,337.399261 
	C114.539696,338.464813 114.783859,339.761108 115.279129,341.131805 
	C115.279129,341.131805 115.518951,341.161011 115.518951,341.161011 
	C115.518951,341.161011 115.581383,340.913086 115.954674,340.673584 
M312.276093,331.231018 
	C311.852112,332.492706 311.428162,333.754364 310.316040,334.763580 
	C310.044556,334.355103 309.721130,333.971741 309.509705,333.534241 
	C307.595673,329.573547 304.005096,330.277344 301.634308,331.910553 
	C300.036346,333.011414 298.967255,336.542816 299.425507,338.592529 
	C300.194580,342.032562 303.353485,341.820251 306.278748,340.814240 
	C307.477905,340.401855 308.764404,340.243530 310.318329,340.649445 
	C314.234894,341.914917 313.633575,339.063232 314.313171,336.302673 
	C315.597809,335.652283 316.882446,335.001923 318.661987,334.100983 
	C318.911316,337.076691 319.080475,339.095825 319.299438,341.709381 
	C320.928711,340.595459 321.973511,339.881134 323.547485,339.827789 
	C329.195190,342.371002 333.480469,341.862030 334.273285,338.389771 
	C334.966431,335.354004 335.307434,332.229706 335.641357,329.127014 
	C335.707733,328.510040 335.168823,327.425415 334.671326,327.256531 
	C334.104523,327.064056 332.812531,327.470734 332.625244,327.935303 
	C330.782318,332.507111 326.432465,330.876160 323.119263,331.227142 
	C323.364471,329.890778 323.609650,328.554382 323.854828,327.217987 
	C322.021759,327.427216 321.126770,328.244537 320.318451,329.140106 
	C319.512878,330.032654 318.794189,331.003662 317.876221,331.445099 
	C317.566010,331.363007 317.255829,331.280945 316.155853,331.110504 
	C315.099670,331.132996 314.043488,331.155487 312.276093,331.231018 
M191.233871,332.312958 
	C189.320084,331.866669 187.187653,330.678497 185.547806,331.161743 
	C183.494781,331.766785 181.272400,333.419067 180.233994,335.249481 
	C179.589371,336.385712 180.526138,339.742981 181.603561,340.232819 
	C183.374924,341.038208 185.854202,340.467438 188.000870,340.201355 
	C189.374054,340.031128 190.685806,339.365387 192.188187,339.699280 
	C194.898514,340.248505 197.608841,340.797729 200.625061,341.408966 
	C201.193176,337.393524 201.620163,334.375549 202.055008,331.302032 
	C195.339722,329.694122 199.923676,336.979370 195.681793,337.691284 
	C195.471939,336.168823 195.224976,334.670929 195.066467,333.163727 
	C194.772568,330.369446 193.678162,330.498779 191.233871,332.312958 
M380.134003,327.076447 
	C377.015198,327.076447 373.896393,327.076447 371.963501,327.076447 
	C372.434113,332.286041 372.815765,336.510345 373.197388,340.734680 
	C373.606964,340.943085 374.016541,341.151520 374.426117,341.359924 
	C375.200592,339.699890 376.558960,338.063446 376.627228,336.374878 
	C376.766907,332.919769 377.838593,330.385864 381.319092,329.967224 
	C381.319092,333.541962 381.319092,337.116669 381.319092,340.691406 
	C382.900299,340.014771 383.342529,338.679169 384.074799,337.528076 
	C384.766876,336.440186 385.763641,335.546173 386.626404,334.566895 
	C387.990051,338.422729 386.603577,343.479492 392.654144,339.718079 
	C392.833496,339.606537 393.290741,339.846191 393.570343,340.006165 
	C397.495605,342.250702 399.951691,340.713440 402.073090,337.137299 
	C403.748627,334.312744 402.701019,330.991486 399.444061,331.092072 
	C397.018555,331.166962 394.630493,332.453735 392.330505,333.168274 
	C388.084198,332.401642 386.499542,326.324890 380.134003,327.076447 
M303.250702,115.399452 
	C306.289551,115.155334 309.328400,114.911217 312.367249,114.667099 
	C310.152374,112.376587 307.801178,110.925529 305.358246,109.650307 
	C302.623169,108.222595 301.604279,106.693451 303.953735,103.898819 
	C305.743073,101.770462 307.065796,99.249855 309.246948,95.906006 
	C304.181915,97.803375 300.498138,99.183319 296.385254,100.724007 
	C294.959778,96.362617 293.692352,92.484871 292.424927,88.607117 
	C290.704407,91.536995 289.753906,94.198982 289.173065,96.939331 
	C288.460968,100.298904 286.888977,100.935867 283.877930,99.420578 
	C281.326447,98.136551 278.588104,97.223793 275.932465,96.146751 
	C275.766449,96.432281 275.600403,96.717812 275.434387,97.003342 
	C277.718414,100.375488 280.002441,103.747635 282.419464,107.316109 
	C278.531036,109.664391 275.011383,111.789948 271.491760,113.915504 
	C271.637482,114.327354 271.783234,114.739197 271.928955,115.151047 
	C274.723145,115.151047 277.517426,115.137039 280.311493,115.154724 
	C283.534332,115.175133 284.765533,116.561195 283.910217,119.878677 
	C283.199158,122.636444 282.921783,125.506027 282.452271,128.326080 
	C282.814148,128.454407 283.175995,128.582718 283.537872,128.711044 
	C286.249207,125.720444 288.960541,122.729851 291.972687,119.407433 
	C295.123108,122.746841 297.944000,125.736977 300.764923,128.727112 
	C301.112671,125.217438 300.400085,122.289238 300.349121,119.349564 
	C300.326843,118.063614 301.657288,116.754211 303.250702,115.399452 
M263.652008,240.820251 
	C261.133362,245.421677 258.614716,250.023117 256.055725,254.698273 
	C266.244781,256.339355 290.182709,233.290497 290.760895,221.232697 
	C284.625183,221.232697 278.495850,221.093246 272.383850,221.356308 
	C271.231812,221.405899 269.559265,222.843811 269.134949,224.008362 
	C267.206024,229.302521 265.637543,234.727997 263.652008,240.820251 
M141.495300,307.978638 
	C145.930267,308.169464 146.020340,304.912079 144.339844,302.822510 
	C141.303833,299.047424 140.886963,296.107971 144.275497,292.478363 
	C145.935638,290.700134 144.186676,283.969940 141.779831,283.410309 
	C139.457031,282.870209 136.212677,281.275696 134.708649,285.477295 
	C134.391357,286.363678 131.946121,287.039795 130.576202,286.897827 
	C125.839027,286.406860 123.696915,288.211792 123.965508,293.020447 
	C124.026703,294.116089 123.449379,295.247406 123.165947,296.362305 
	C122.913536,296.311584 122.661118,296.260834 122.408707,296.210114 
	C121.739357,293.206177 121.070007,290.202271 120.453697,287.436401 
	C117.798637,287.436401 115.583519,287.436401 112.775864,287.436401 
	C114.413780,292.869080 116.097084,297.692780 117.232437,302.642181 
	C117.614548,304.307953 116.635567,306.300232 116.219276,308.129761 
	C115.949699,309.314484 115.172089,310.559265 115.394783,311.613983 
	C115.526649,312.238464 117.130524,312.838745 118.126839,312.946228 
	C123.448631,313.520416 126.257225,311.434479 127.840553,305.835449 
	C129.129379,301.277832 130.334167,296.696503 131.576218,292.125671 
	C132.059891,292.001740 132.543579,291.877808 133.027252,291.753845 
	C133.682419,293.097595 134.693268,294.386841 134.909378,295.797821 
	C135.233215,297.912231 135.075806,300.115295 134.985397,302.274933 
	C134.818512,306.262390 136.620193,308.198151 141.495300,307.978638 
M94.952461,283.460083 
	C92.849068,279.169678 89.466583,281.436981 86.350212,281.433777 
	C86.350212,290.352631 86.350212,298.898773 86.350212,307.376312 
	C92.829498,308.484192 92.951851,308.387146 92.963417,302.455658 
	C92.965683,301.292145 92.934113,300.127869 92.968323,298.965393 
	C92.997314,297.980743 93.098854,296.998230 93.168289,296.014771 
	C94.780190,300.047791 96.037239,303.713654 101.380432,301.436584 
	C101.968407,299.552734 102.542244,297.714172 103.116081,295.875610 
	C104.038109,298.238892 104.021584,300.387878 104.041336,302.536499 
	C104.092247,308.074921 104.994232,308.705902 110.647537,307.119934 
	C110.647537,298.588928 110.647537,290.041229 110.647537,281.498932 
	C105.813919,280.319794 102.157150,280.347900 101.263321,286.256256 
	C100.898743,288.666168 99.643227,290.941345 98.415955,294.314148 
	C97.082253,290.171722 96.126465,287.203064 94.952461,283.460083 
M403.159760,286.998993 
	C400.167114,287.002960 397.153229,286.804901 394.192780,287.115601 
	C393.090057,287.231354 391.248230,288.500519 391.214874,289.304749 
	C390.962952,295.379333 391.077301,301.469086 391.077301,307.585968 
	C394.061584,307.585968 396.279968,307.585968 399.372253,307.585968 
	C399.372253,302.651459 399.372253,298.104767 399.372253,293.558075 
	C399.931610,293.574799 400.490936,293.591522 401.050293,293.608246 
	C401.050293,298.265808 401.050293,302.923370 401.050293,307.604004 
	C404.074982,307.604004 406.297516,307.604004 409.000000,307.604004 
	C409.000000,304.917633 408.948914,302.621429 409.014282,300.328552 
	C409.080597,298.002625 409.261871,295.679962 409.392792,293.355865 
	C410.138336,293.342010 410.883850,293.328156 411.629395,293.314301 
	C411.744141,297.044861 411.987396,300.777466 411.929382,304.505310 
	C411.889404,307.072662 412.826538,308.056030 415.417206,308.040192 
	C417.870728,308.025177 419.133179,307.351715 419.042847,304.654266 
	C418.909393,300.668274 418.900848,296.671173 419.031281,292.685120 
	C419.175629,288.273895 416.935547,286.789398 412.847290,286.993896 
	C409.928009,287.139954 406.995056,287.012421 403.159760,286.998993 
M265.938721,296.812775 
	C265.620544,294.855011 265.803314,292.592285 264.847656,291.022644 
	C263.829102,289.349670 261.808319,287.335114 260.095551,287.207397 
	C253.901138,286.745514 247.643677,287.208221 241.427231,286.933014 
	C237.775116,286.771362 236.683365,288.219421 237.039993,291.568848 
	C237.424942,295.184479 237.820892,298.811523 237.924576,302.441772 
	C238.099289,308.557953 238.517563,308.923553 245.001251,306.979553 
	C245.001251,304.646362 244.933197,302.172180 245.021515,299.703583 
	C245.100357,297.500092 245.351990,295.302734 245.609055,292.078033 
	C247.096344,293.501404 247.715500,293.812805 247.733170,294.155182 
	C247.878616,296.974152 247.947159,299.798584 247.972778,302.621918 
	C248.026382,308.526733 248.846741,309.069702 255.025391,306.878082 
	C255.025391,304.506104 254.951828,302.019135 255.047684,299.538666 
	C255.130768,297.389130 255.405914,295.246979 255.596176,293.101562 
	C256.269531,293.144562 256.942902,293.187561 257.616272,293.230560 
	C257.746216,295.588623 257.928314,297.945251 257.991730,300.305115 
	C258.056885,302.729309 258.006165,305.156677 258.006165,307.465881 
	C265.235260,308.428619 265.854675,307.922516 265.937378,301.710480 
	C265.955078,300.379883 265.940735,299.048889 265.938721,296.812775 
M331.399017,287.023071 
	C319.989594,287.004974 319.989594,287.004974 319.999390,297.639740 
	C320.007507,306.464874 320.007172,306.460876 328.966583,305.703552 
	C329.162109,305.687012 329.390533,306.059692 330.686493,307.222015 
	C327.613525,307.555023 325.442413,307.562286 323.417877,308.110687 
	C322.476410,308.365723 321.208954,309.669647 321.206421,310.501495 
	C321.203918,311.324127 322.540619,312.766083 323.409332,312.862793 
	C326.207062,313.174225 329.060059,312.974487 331.891479,313.001831 
	C334.946381,313.031311 337.110321,311.991302 337.268829,308.557831 
	C337.513336,303.261353 337.569061,297.950439 338.002625,292.670135 
	C338.361389,288.300385 336.722595,286.386780 331.399017,287.023071 
M274.550262,287.015594 
	C272.605835,287.015594 270.661377,287.015594 268.482239,287.015594 
	C268.482239,295.847076 268.482239,304.091431 268.482239,312.575623 
	C270.949249,312.575623 273.291412,312.575623 275.694885,312.575623 
	C275.920074,310.716797 276.095306,309.270172 276.268341,307.841888 
	C285.670074,308.306488 286.012634,307.980591 286.019867,298.792694 
	C286.020111,298.460785 286.020874,298.128845 286.021027,297.796936 
	C286.025574,287.016205 286.025574,287.016205 274.550262,287.015594 
M146.190750,300.919708 
	C147.213806,303.094635 147.922791,306.868347 149.319199,307.142670 
	C153.400284,307.944366 157.828461,307.764282 161.956726,307.037750 
	C163.118546,306.833252 163.715698,303.420288 164.571838,301.478943 
	C162.466751,301.305359 160.338242,300.875305 158.266266,301.064423 
	C157.200333,301.161713 156.235092,302.362305 154.431320,303.615173 
	C153.591675,298.177124 156.942001,299.178589 159.281693,298.995697 
	C161.861786,298.794006 165.144150,300.139221 165.062637,295.256683 
	C164.992676,291.067230 164.712296,287.591248 159.540726,287.004150 
	C148.050476,285.699707 146.024445,287.415588 146.061874,298.558350 
	C146.063538,299.057556 146.079849,299.556671 146.190750,300.919708 
M187.877823,299.012726 
	C190.451248,299.012726 193.024673,299.012726 195.479355,299.012726 
	C196.597183,288.938141 194.922272,287.010498 185.952621,286.999695 
	C184.953018,286.998474 183.949753,286.943268 182.954407,287.008179 
	C177.731293,287.348755 177.171524,291.069489 177.030945,295.152679 
	C176.671463,305.594696 177.324615,306.914215 182.621216,307.918640 
	C184.392242,308.254517 186.276672,308.010651 188.109100,308.001099 
	C194.361603,307.968567 195.070908,307.211121 194.931702,299.992004 
	C191.864197,300.748230 188.956223,301.465149 185.643524,302.281830 
	C185.583389,302.058685 185.058319,301.143951 185.224548,300.379608 
	C185.344101,299.829834 186.385971,299.480621 187.877823,299.012726 
M302.418884,307.588715 
	C306.386871,307.714417 310.359070,307.979767 314.319580,307.859406 
	C315.216278,307.832153 316.803894,306.489990 316.830872,305.710114 
	C317.040894,299.636566 316.946960,293.552521 316.946960,287.392761 
	C314.008331,287.392761 311.792053,287.392761 308.709900,287.392761 
	C308.709900,292.312592 308.709900,296.869537 308.709900,301.426453 
	C308.150604,301.419128 307.591339,301.411774 307.032043,301.404419 
	C307.032043,296.767365 307.032043,292.130280 307.032043,287.376587 
	C304.005768,287.376587 301.789154,287.376587 299.049683,287.376587 
	C299.049683,292.182739 298.776459,296.652863 299.169769,301.063599 
	C299.354828,303.139404 300.821075,305.101013 302.418884,307.588715 
M234.942078,291.962067 
	C233.582825,290.331573 232.264023,287.363037 230.856766,287.320465 
	C226.565308,287.190796 222.226242,288.111206 217.947388,288.860931 
	C217.494720,288.940277 217.077530,290.587860 217.054779,291.517944 
	C216.957047,295.512726 216.643188,299.562897 217.190292,303.483704 
	C217.417114,305.109314 219.585220,307.718567 220.904114,307.739258 
	C231.656540,307.908051 235.695831,310.567688 234.958984,296.367157 
	C234.898651,295.204376 234.950226,294.035797 234.942078,291.962067 
M382.191406,287.000427 
	C380.032562,287.010132 377.865509,286.904724 375.716827,287.057434 
	C372.728394,287.269836 369.528717,288.085724 370.170349,291.829620 
	C371.095612,297.228607 366.560211,304.085358 374.119446,307.823486 
	C374.400085,307.962280 374.771362,307.950928 375.100739,307.952667 
	C377.757507,307.966888 380.432800,308.166046 383.066437,307.921234 
	C386.253723,307.625031 388.223602,306.020325 388.035492,302.394226 
	C387.863678,299.081696 387.942169,295.752106 388.013275,292.432220 
	C388.086517,289.011078 386.646545,287.073914 382.191406,287.000427 
M209.832062,299.981812 
	C208.388489,300.475952 206.944931,300.970062 205.346466,301.517212 
	C205.346466,298.650391 205.346466,296.125519 205.346466,294.109131 
	C208.581024,294.463501 211.507751,294.784149 214.434494,295.104797 
	C214.847595,294.545807 215.260696,293.986847 215.673798,293.427856 
	C214.047073,291.332916 212.820358,288.439270 210.681015,287.367737 
	C208.544159,286.297455 205.489609,286.936127 202.844711,287.030701 
	C198.244827,287.195190 197.247498,290.453735 197.062393,294.206940 
	C196.569565,304.199646 197.691208,307.435150 202.087906,307.915131 
	C204.867752,308.218567 207.953186,308.579498 210.450760,307.672882 
	C212.318176,306.994995 213.465454,304.333344 214.933395,302.555023 
	C213.512161,301.694733 212.090927,300.834473 209.832062,299.981812 
M367.467041,305.756775 
	C367.612610,304.028900 367.758209,302.300995 367.982117,299.643524 
	C364.472961,300.261047 361.745209,300.741058 358.174072,301.369476 
	C358.310486,298.633209 358.442383,295.986969 358.569366,293.439850 
	C362.189728,294.400055 364.771942,295.084930 367.354126,295.769775 
	C367.838776,295.399628 368.323425,295.029449 368.808075,294.659271 
	C367.681305,292.233582 367.056824,288.274994 365.321533,287.706360 
	C361.879639,286.578583 357.691254,286.743439 354.090485,287.578064 
	C352.677490,287.905579 351.050903,291.535675 351.201019,293.535645 
	C351.553772,298.233978 348.221527,304.670898 353.663361,307.195740 
	C357.211731,308.842133 362.464020,306.816010 367.467041,305.756775 
M240.265701,100.442062 
	C239.003174,99.665993 237.818848,98.695404 236.463425,98.150360 
	C232.415482,96.522575 228.709808,97.542259 226.240692,100.917274 
	C223.794296,104.261208 223.902557,108.288422 226.355423,111.632896 
	C228.885437,115.082542 232.627838,115.819717 236.634293,114.353348 
	C241.969574,112.400627 243.367599,107.733765 240.265701,100.442062 
M167.851898,307.587463 
	C170.800507,307.820160 174.697266,309.613861 174.873764,304.382812 
	C175.114594,297.245026 175.095795,290.085480 174.758408,282.955597 
	C174.709076,281.912811 172.409348,280.511719 170.926636,280.115356 
	C168.925507,279.580475 166.945541,279.981018 167.008331,282.998444 
	C167.174118,290.965485 167.204361,298.935333 167.851898,307.587463 
M294.395447,330.874146 
	C293.395721,330.982971 291.764496,331.528320 291.492188,331.134338 
	C288.071442,326.185730 288.139832,326.162109 285.049011,331.176025 
	C284.769623,331.629272 284.059418,331.816925 283.291931,332.284790 
	C282.791260,331.114105 282.570099,330.110352 281.997772,329.382843 
	C281.295715,328.490448 280.300079,327.829041 279.431366,327.067780 
	C279.087219,328.203247 278.481995,329.333984 278.461212,330.475342 
	C278.431366,332.112762 278.645264,333.809082 279.087708,335.387024 
	C279.656158,337.414398 280.554199,339.349304 281.310303,341.324066 
	C282.896332,339.924347 284.516693,338.560822 286.050446,337.105988 
	C286.614990,336.570526 286.975189,335.819550 287.855072,334.553040 
	C288.583313,337.014191 289.122986,338.838226 289.859192,341.326385 
	C297.712036,339.710907 295.896454,331.787476 299.752991,327.954620 
	C299.258087,327.498688 298.763214,327.042725 298.268311,326.586792 
	C297.090485,327.782104 295.912659,328.977417 294.395447,330.874146 
M296.270844,280.713837 
	C293.978149,280.484955 291.685455,280.256073 288.995850,279.987579 
	C288.995850,285.417603 288.995850,289.883423 288.995850,294.349243 
	C288.995850,298.761139 288.995850,303.173035 288.995850,307.634247 
	C291.698334,307.634247 293.596558,307.634247 296.232239,307.634247 
	C296.232239,298.700897 296.232239,290.113800 296.270844,280.713837 
M171.386536,327.303436 
	C170.189636,327.310089 168.943344,327.141388 167.832687,327.454193 
	C167.574615,327.526886 167.590836,329.284180 167.804184,330.190186 
	C168.523132,333.243439 169.344498,336.275055 170.229080,339.284912 
	C170.433243,339.979614 171.149796,341.129456 171.448441,341.074799 
	C172.273575,340.923767 173.380676,340.428131 173.707230,339.747681 
	C175.648026,335.703491 177.399384,331.568420 179.558411,326.685974 
	C178.057816,327.130157 176.774139,327.657288 175.441101,327.860199 
	C174.284729,328.036224 173.072479,327.845032 171.386536,327.303436 
M228.635986,339.446625 
	C231.240082,334.859650 230.452805,331.131409 226.623047,331.205292 
	C224.355530,331.249023 221.709061,332.668243 220.047363,334.298859 
	C219.206650,335.123840 219.746384,339.175964 220.400650,339.378113 
	C222.795135,340.117859 225.499969,339.853058 228.635986,339.446625 
M209.211273,328.413788 
	C213.469650,331.853210 210.805099,336.401398 210.873032,341.512054 
	C212.570908,340.635498 214.249878,340.339203 214.617325,339.481262 
	C216.301132,335.549530 217.617874,331.460571 219.338959,326.669373 
	C216.539429,326.960358 212.950104,327.333435 209.211273,328.413788 
M340.237488,302.931061 
	C340.237488,304.498444 340.237488,306.065796 340.237488,307.557129 
	C343.187347,307.557129 345.412170,307.557129 347.589966,307.557129 
	C347.589966,305.216766 347.589966,303.318665 347.589966,300.586426 
	C344.907532,301.196625 342.761566,301.684814 340.237488,302.931061 
z"/>
<path fill="#40B6FA" opacity="1.000000" stroke="none" 
	d="
M174.000153,227.001907 
	C174.448868,226.999985 174.897598,226.998077 176.098831,226.996246 
	C182.723343,225.966064 188.725342,225.403305 194.441742,223.813553 
	C208.772964,219.827972 222.974884,215.377457 237.584442,211.072357 
	C247.609543,206.428375 257.278839,201.825470 267.268616,197.137421 
	C267.903381,196.749359 268.217682,196.446426 268.630981,196.224304 
	C268.729950,196.305130 268.958374,196.190445 269.276123,196.166809 
	C270.094299,195.789932 270.594727,195.436691 271.086243,195.054596 
	C271.077332,195.025711 271.024780,195.055542 271.319702,195.023758 
	C272.071136,194.651810 272.527649,194.311646 273.298157,193.932388 
	C274.429047,193.282120 275.245941,192.670944 276.028473,192.040314 
	C275.994080,192.020859 276.058899,192.072098 276.368835,192.030930 
	C277.461395,191.361755 278.244019,190.733749 279.328766,190.029602 
	C280.431549,189.325302 281.232208,188.697174 282.376160,188.021118 
	C286.170898,184.995605 289.622345,182.018005 293.073792,179.040421 
	C293.645813,178.553848 294.217834,178.067245 295.228058,177.203156 
	C298.462555,173.232681 301.258820,169.639709 304.055084,166.046738 
	C304.055084,166.046738 304.009949,166.003113 304.320923,165.829391 
	C305.757141,162.106552 306.882416,158.557419 308.007690,155.008286 
	C308.057343,154.567551 308.106964,154.126831 308.355377,153.091949 
	C307.771820,149.556259 306.989502,146.614685 306.207184,143.673111 
	C306.207184,143.673111 306.163361,143.316330 306.061829,142.846741 
	C305.359589,141.554550 304.758820,140.731918 304.158081,139.909302 
	C304.158081,139.909302 304.093903,139.893524 304.165588,139.602997 
	C306.476685,139.895920 308.716156,140.479355 310.955597,141.062790 
	C310.955597,141.062790 311.003967,141.006531 311.131897,141.321030 
	C313.815674,143.782669 316.371521,145.929810 318.927338,148.076965 
	C318.927338,148.076965 318.975891,147.989868 318.997498,148.393616 
	C323.016205,154.832306 322.344513,161.428421 321.335022,168.065155 
	C321.211365,168.456940 321.087738,168.848724 320.699341,169.742554 
	C320.266724,170.819366 320.098846,171.394150 319.930969,171.968933 
	C319.930969,171.968933 320.000519,171.992126 319.726501,172.138168 
	C319.272125,172.840561 319.091736,173.396912 318.911346,173.953262 
	C318.911346,173.953262 318.998291,173.990997 318.676086,174.136536 
	C312.869385,180.831863 307.384888,187.381638 301.900421,193.931427 
	C301.587219,194.034592 301.337952,194.222519 300.816681,194.801865 
	C300.302582,195.394363 300.124420,195.680222 299.946259,195.966080 
	C299.946259,195.966080 300.006256,195.985672 299.624268,196.019531 
	C298.549957,197.700394 297.857635,199.347382 297.165344,200.994385 
	C297.088806,202.058624 297.012238,203.122849 296.697205,204.864059 
	C296.001312,208.031769 295.543915,210.522507 295.086548,213.013229 
	C295.086517,213.013229 294.882263,213.298401 294.392212,213.540863 
	C288.426361,213.824982 282.930267,214.183395 277.479431,213.834656 
	C269.135529,213.300827 263.139709,218.346146 255.717117,221.474609 
	C258.291840,222.910172 260.086243,223.910660 261.880646,224.911148 
	C261.880646,224.911148 261.883728,224.958694 261.625122,225.178986 
	C260.220612,229.256943 259.074707,233.114594 257.928802,236.972244 
	C257.928802,236.972244 257.750824,237.257034 257.484314,237.665787 
	C255.424576,241.670715 253.631378,245.266876 251.838165,248.863037 
	C251.838165,248.863037 251.867233,248.975708 251.632324,249.021896 
	C251.251770,249.378479 251.106125,249.688873 250.960480,249.999268 
	C250.960464,249.999268 250.773514,250.246109 250.464996,250.535660 
	C249.723099,251.510742 249.289703,252.196274 248.856308,252.881790 
	C248.856308,252.881790 248.991730,252.980682 248.629486,252.991394 
	C247.824112,253.652817 247.380997,254.303528 246.937897,254.954239 
	C246.937881,254.954239 246.992569,255.004456 246.658066,255.105194 
	C242.539520,257.170258 238.755493,259.134583 234.971451,261.098877 
	C234.971451,261.098877 234.966232,261.017273 234.920135,260.554199 
	C234.874023,249.519730 234.874023,238.948318 234.874023,228.435211 
	C227.495377,228.069870 226.978455,228.497421 227.237473,233.988220 
	C227.179245,242.669540 227.121017,251.350845 226.635193,260.510223 
	C223.132004,259.670624 220.056412,258.352997 216.980820,257.035370 
	C216.980820,257.035370 217.000931,256.998779 216.907455,256.765045 
	C216.540497,256.358246 216.267029,256.185181 215.993546,256.012115 
	C214.864899,254.778046 213.736267,253.543976 212.310257,251.812653 
	C211.062820,250.193756 210.112747,249.072113 209.162674,247.950470 
	C208.971924,247.401550 208.781174,246.852631 208.324875,245.769470 
	C207.106064,243.717987 206.152832,242.200760 205.199585,240.683533 
	C205.199585,240.683533 205.176987,240.322601 205.090515,239.781708 
	C202.309769,231.790436 201.901810,231.597580 194.629257,235.259125 
	C197.697098,240.641479 200.744247,245.987564 203.791412,251.333649 
	C203.791412,251.333649 203.848373,251.686325 203.968231,252.194336 
	C204.401611,253.810349 204.715118,254.918320 204.673523,256.047974 
	C203.201080,255.733688 202.083740,255.397736 200.966385,255.061783 
	C200.966385,255.061783 200.989975,254.997406 200.780334,254.754028 
	C199.390991,253.991455 198.211273,253.472260 197.031555,252.953079 
	C197.031555,252.953079 196.998047,252.997635 196.859467,252.686218 
	C191.145203,247.474365 185.702286,242.408279 179.916290,237.770187 
	C178.081985,236.299820 175.332611,235.970993 173.002014,235.119781 
	C171.941360,235.039032 170.880722,234.958282 169.156036,234.659393 
	C167.313812,234.359924 166.135605,234.278610 164.957413,234.197296 
	C164.522003,234.129654 164.086609,234.061996 163.033722,233.771942 
	C159.159058,232.790314 155.901855,232.031097 152.644653,231.271866 
	C152.717590,230.995483 152.790512,230.719101 152.863449,230.442719 
	C159.909012,229.295776 166.954590,228.148849 174.000153,227.001907 
z"/>
<path fill="#45B6F6" opacity="1.000000" stroke="none" 
	d="
M222.214447,155.048080 
	C221.886642,155.512177 221.558838,155.976288 220.862000,156.784760 
	C220.349838,157.426224 220.206741,157.723328 220.063644,158.020447 
	C220.063644,158.020447 219.871124,158.314087 219.525421,158.597321 
	C218.715866,159.544128 218.251999,160.207703 217.788147,160.871277 
	C209.660446,170.598770 201.060257,179.789841 189.355820,185.572876 
	C193.813477,178.684875 198.941086,172.129852 202.569336,164.829620 
	C207.807816,154.289566 212.180222,143.295715 216.477463,132.321381 
	C218.529068,127.081924 217.181473,125.355774 211.575821,126.193893 
	C205.497879,127.102615 199.588760,129.140533 194.107269,130.556534 
	C194.696213,125.317520 201.589783,118.512932 209.418121,118.276566 
	C216.640747,118.058502 223.923569,119.089951 231.148529,119.863754 
	C242.680740,121.098877 254.184372,122.599342 265.708038,123.918091 
	C266.925385,124.057396 268.195984,123.731438 271.073853,123.477097 
	C268.846252,125.262039 267.678680,126.639633 266.192108,127.313393 
	C259.203857,130.480667 251.710037,131.852692 244.259750,130.357178 
	C237.911240,129.082825 235.573761,132.116837 233.623444,137.650543 
	C230.527191,142.447128 227.788574,146.737030 225.049957,151.026917 
	C225.049957,151.026917 224.915344,151.394211 224.658981,151.724396 
	C224.255310,152.364029 224.108002,152.673477 223.960693,152.982925 
	C223.654907,153.128143 223.414856,153.345856 222.917007,153.932755 
	C222.304718,154.427109 222.178375,154.699982 222.214447,155.048080 
z"/>
<path fill="#49B7F6" opacity="1.000000" stroke="none" 
	d="
M302.812073,115.427452 
	C301.657288,116.754211 300.326843,118.063614 300.349121,119.349564 
	C300.400085,122.289238 301.112671,125.217438 300.764923,128.727112 
	C297.944000,125.736977 295.123108,122.746841 291.972687,119.407433 
	C288.960541,122.729851 286.249207,125.720444 283.537872,128.711044 
	C283.175995,128.582718 282.814148,128.454407 282.452271,128.326080 
	C282.921783,125.506027 283.199158,122.636444 283.910217,119.878677 
	C284.765533,116.561195 283.534332,115.175133 280.311493,115.154724 
	C277.517426,115.137039 274.723145,115.151047 271.928955,115.151047 
	C271.783234,114.739197 271.637482,114.327354 271.491760,113.915504 
	C275.011383,111.789948 278.531036,109.664391 282.419464,107.316109 
	C280.002441,103.747635 277.718414,100.375488 275.434387,97.003342 
	C275.600403,96.717812 275.766449,96.432281 275.932465,96.146751 
	C278.588104,97.223793 281.326447,98.136551 283.877930,99.420578 
	C286.888977,100.935867 288.460968,100.298904 289.173065,96.939331 
	C289.753906,94.198982 290.704407,91.536995 292.424927,88.607117 
	C293.692352,92.484871 294.959778,96.362617 296.385254,100.724007 
	C300.498138,99.183319 304.181915,97.803375 309.246948,95.906006 
	C307.065796,99.249855 305.743073,101.770462 303.953735,103.898819 
	C301.604279,106.693451 302.623169,108.222595 305.358246,109.650307 
	C307.801178,110.925529 310.152374,112.376587 312.367249,114.667099 
	C309.328400,114.911217 306.289551,115.155334 302.812073,115.427452 
z"/>
<path fill="#D9D9D9" opacity="1.000000" stroke="none" 
	d="
M235.970016,207.098572 
	C235.971008,195.805542 236.051163,184.511566 235.916809,173.220154 
	C235.883591,170.428665 236.522491,168.695801 239.567810,168.676941 
	C244.130112,168.648727 248.692703,168.669632 254.020599,168.669632 
	C254.020599,179.603806 254.020599,189.834549 253.633820,200.574432 
	C247.488022,203.088577 241.729019,205.093567 235.970016,207.098572 
z"/>
<path fill="#DADADA" opacity="1.000000" stroke="none" 
	d="
M266.022644,194.950073 
	C265.361298,193.839966 264.145172,192.739304 264.126129,191.618286 
	C263.971039,182.487152 264.085358,173.351898 264.018188,164.218628 
	C263.990692,160.479568 265.472931,158.999374 269.392609,158.981064 
	C283.003174,158.917450 283.005951,158.791275 283.002655,172.138672 
	C283.001740,175.791977 282.973938,179.445267 282.735229,183.656433 
	C282.275360,184.383957 282.107025,184.605576 281.675873,184.945068 
	C280.182526,185.999939 279.020325,186.988876 277.591797,188.004974 
	C276.899353,188.316284 276.473297,188.600433 275.708435,188.939514 
	C273.888580,189.972534 272.407471,190.950623 270.632446,192.074432 
	C268.899902,193.130142 267.461273,194.040115 266.022644,194.950073 
z"/>
<path fill="#46B8F8" opacity="1.000000" stroke="none" 
	d="
M263.795715,240.463226 
	C265.637543,234.727997 267.206024,229.302521 269.134949,224.008362 
	C269.559265,222.843811 271.231812,221.405899 272.383850,221.356308 
	C278.495850,221.093246 284.625183,221.232697 290.760895,221.232697 
	C290.182709,233.290497 266.244781,256.339355 256.055725,254.698273 
	C258.614716,250.023117 261.133362,245.421677 263.795715,240.463226 
z"/>
<path fill="#D9D9D9" opacity="1.000000" stroke="none" 
	d="
M209.047638,217.040527 
	C208.371521,216.270584 207.117920,215.508713 207.107040,214.729477 
	C206.972519,205.103058 207.019257,195.474106 207.019257,185.425293 
	C213.301529,185.425293 219.192261,185.425293 225.892090,185.425293 
	C225.892090,194.243866 225.892090,203.144958 225.560120,212.507111 
	C224.853561,212.972687 224.478973,212.977219 223.734436,212.981720 
	C222.499969,213.639145 221.635483,214.296616 220.384872,214.946625 
	C218.016022,215.294922 216.033279,215.650681 213.699448,215.997925 
	C212.519257,216.313782 211.690170,216.638138 210.558212,216.968918 
	C209.852783,216.997070 209.450211,217.018799 209.047638,217.040527 
z"/>
<path fill="#DADADA" opacity="1.000000" stroke="none" 
	d="
M141.070129,307.977509 
	C136.620193,308.198151 134.818512,306.262390 134.985397,302.274933 
	C135.075806,300.115295 135.233215,297.912231 134.909378,295.797821 
	C134.693268,294.386841 133.682419,293.097595 133.027252,291.753845 
	C132.543579,291.877808 132.059891,292.001740 131.576218,292.125671 
	C130.334167,296.696503 129.129379,301.277832 127.840553,305.835449 
	C126.257225,311.434479 123.448631,313.520416 118.126839,312.946228 
	C117.130524,312.838745 115.526649,312.238464 115.394783,311.613983 
	C115.172089,310.559265 115.949699,309.314484 116.219276,308.129761 
	C116.635567,306.300232 117.614548,304.307953 117.232437,302.642181 
	C116.097084,297.692780 114.413780,292.869080 112.775864,287.436401 
	C115.583519,287.436401 117.798637,287.436401 120.453697,287.436401 
	C121.070007,290.202271 121.739357,293.206177 122.408707,296.210114 
	C122.661118,296.260834 122.913536,296.311584 123.165947,296.362305 
	C123.449379,295.247406 124.026703,294.116089 123.965508,293.020447 
	C123.696915,288.211792 125.839027,286.406860 130.576202,286.897827 
	C131.946121,287.039795 134.391357,286.363678 134.708649,285.477295 
	C136.212677,281.275696 139.457031,282.870209 141.779831,283.410309 
	C144.186676,283.969940 145.935638,290.700134 144.275497,292.478363 
	C140.886963,296.107971 141.303833,299.047424 144.339844,302.822510 
	C146.020340,304.912079 145.930267,308.169464 141.070129,307.977509 
z"/>
<path fill="#DADADA" opacity="1.000000" stroke="none" 
	d="
M95.061569,283.847229 
	C96.126465,287.203064 97.082253,290.171722 98.415955,294.314148 
	C99.643227,290.941345 100.898743,288.666168 101.263321,286.256256 
	C102.157150,280.347900 105.813919,280.319794 110.647537,281.498932 
	C110.647537,290.041229 110.647537,298.588928 110.647537,307.119934 
	C104.994232,308.705902 104.092247,308.074921 104.041336,302.536499 
	C104.021584,300.387878 104.038109,298.238892 103.116081,295.875610 
	C102.542244,297.714172 101.968407,299.552734 101.380432,301.436584 
	C96.037239,303.713654 94.780190,300.047791 93.168289,296.014771 
	C93.098854,296.998230 92.997314,297.980743 92.968323,298.965393 
	C92.934113,300.127869 92.965683,301.292145 92.963417,302.455658 
	C92.951851,308.387146 92.829498,308.484192 86.350212,307.376312 
	C86.350212,298.898773 86.350212,290.352631 86.350212,281.433777 
	C89.466583,281.436981 92.849068,279.169678 95.061569,283.847229 
z"/>
<path fill="#DBDBDB" opacity="1.000000" stroke="none" 
	d="
M403.613983,287.002960 
	C406.995056,287.012421 409.928009,287.139954 412.847290,286.993896 
	C416.935547,286.789398 419.175629,288.273895 419.031281,292.685120 
	C418.900848,296.671173 418.909393,300.668274 419.042847,304.654266 
	C419.133179,307.351715 417.870728,308.025177 415.417206,308.040192 
	C412.826538,308.056030 411.889404,307.072662 411.929382,304.505310 
	C411.987396,300.777466 411.744141,297.044861 411.629395,293.314301 
	C410.883850,293.328156 410.138336,293.342010 409.392792,293.355865 
	C409.261871,295.679962 409.080597,298.002625 409.014282,300.328552 
	C408.948914,302.621429 409.000000,304.917633 409.000000,307.604004 
	C406.297516,307.604004 404.074982,307.604004 401.050293,307.604004 
	C401.050293,302.923370 401.050293,298.265808 401.050293,293.608246 
	C400.490936,293.591522 399.931610,293.574799 399.372253,293.558075 
	C399.372253,298.104767 399.372253,302.651459 399.372253,307.585968 
	C396.279968,307.585968 394.061584,307.585968 391.077301,307.585968 
	C391.077301,301.469086 390.962952,295.379333 391.214874,289.304749 
	C391.248230,288.500519 393.090057,287.231354 394.192780,287.115601 
	C397.153229,286.804901 400.167114,287.002960 403.613983,287.002960 
z"/>
<path fill="#DADADA" opacity="1.000000" stroke="none" 
	d="
M265.939941,297.265442 
	C265.940735,299.048889 265.955078,300.379883 265.937378,301.710480 
	C265.854675,307.922516 265.235260,308.428619 258.006165,307.465881 
	C258.006165,305.156677 258.056885,302.729309 257.991730,300.305115 
	C257.928314,297.945251 257.746216,295.588623 257.616272,293.230560 
	C256.942902,293.187561 256.269531,293.144562 255.596176,293.101562 
	C255.405914,295.246979 255.130768,297.389130 255.047684,299.538666 
	C254.951828,302.019135 255.025391,304.506104 255.025391,306.878082 
	C248.846741,309.069702 248.026382,308.526733 247.972778,302.621918 
	C247.947159,299.798584 247.878616,296.974152 247.733170,294.155182 
	C247.715500,293.812805 247.096344,293.501404 245.609055,292.078033 
	C245.351990,295.302734 245.100357,297.500092 245.021515,299.703583 
	C244.933197,302.172180 245.001251,304.646362 245.001251,306.979553 
	C238.517563,308.923553 238.099289,308.557953 237.924576,302.441772 
	C237.820892,298.811523 237.424942,295.184479 237.039993,291.568848 
	C236.683365,288.219421 237.775116,286.771362 241.427231,286.933014 
	C247.643677,287.208221 253.901138,286.745514 260.095551,287.207397 
	C261.808319,287.335114 263.829102,289.349670 264.847656,291.022644 
	C265.803314,292.592285 265.620544,294.855011 265.939941,297.265442 
z"/>
<path fill="#DADADA" opacity="1.000000" stroke="none" 
	d="
M183.085434,222.990112 
	C181.625763,222.922333 180.166077,222.854538 178.090164,222.758148 
	C178.090164,215.428864 178.009842,208.351379 178.204895,201.281479 
	C178.226196,200.509445 179.802200,199.181137 180.710083,199.134109 
	C185.173218,198.903015 189.667282,198.833511 194.119247,199.148193 
	C195.145477,199.220734 196.818283,200.984375 196.878571,202.044556 
	C197.169739,207.165817 197.216965,212.326691 196.865372,217.440170 
	C196.780167,218.679306 194.854324,219.791870 193.025269,220.982910 
	C191.197159,221.331970 190.118652,221.660751 188.664368,221.993134 
	C187.139389,222.317871 185.990204,222.639023 184.547775,222.963531 
	C183.864838,222.974625 183.475143,222.982361 183.085434,222.990112 
z"/>
<path fill="#DADADA" opacity="1.000000" stroke="none" 
	d="
M331.861328,287.021088 
	C336.722595,286.386780 338.361389,288.300385 338.002625,292.670135 
	C337.569061,297.950439 337.513336,303.261353 337.268829,308.557831 
	C337.110321,311.991302 334.946381,313.031311 331.891479,313.001831 
	C329.060059,312.974487 326.207062,313.174225 323.409332,312.862793 
	C322.540619,312.766083 321.203918,311.324127 321.206421,310.501495 
	C321.208954,309.669647 322.476410,308.365723 323.417877,308.110687 
	C325.442413,307.562286 327.613525,307.555023 330.686493,307.222015 
	C329.390533,306.059692 329.162109,305.687012 328.966583,305.703552 
	C320.007172,306.460876 320.007507,306.464874 319.999390,297.639740 
	C319.989594,287.004974 319.989594,287.004974 331.861328,287.021088 
M330.756134,293.961121 
	C329.668213,293.763611 328.580322,293.566101 327.492401,293.368591 
	C327.492401,295.851593 327.492401,298.334595 327.492401,300.817596 
	C328.061371,300.867340 328.630371,300.917053 329.199341,300.966797 
	C329.736847,298.887848 330.274353,296.808929 330.756134,293.961121 
z"/>
<path fill="#DADADA" opacity="1.000000" stroke="none" 
	d="
M275.023560,287.023346 
	C286.025574,287.016205 286.025574,287.016205 286.021027,297.796936 
	C286.020874,298.128845 286.020111,298.460785 286.019867,298.792694 
	C286.012634,307.980591 285.670074,308.306488 276.268341,307.841888 
	C276.095306,309.270172 275.920074,310.716797 275.694885,312.575623 
	C273.291412,312.575623 270.949249,312.575623 268.482239,312.575623 
	C268.482239,304.091431 268.482239,295.847076 268.482239,287.015594 
	C270.661377,287.015594 272.605835,287.015594 275.023560,287.023346 
M277.478333,302.719177 
	C280.410736,299.177612 279.051178,295.759186 278.101776,292.328979 
	C277.388275,292.349640 276.674805,292.370300 275.961304,292.390961 
	C276.204010,295.757385 276.446747,299.123810 277.478333,302.719177 
z"/>
<path fill="#DBDBDB" opacity="1.000000" stroke="none" 
	d="
M146.140045,300.487793 
	C146.079849,299.556671 146.063538,299.057556 146.061874,298.558350 
	C146.024445,287.415588 148.050476,285.699707 159.540726,287.004150 
	C164.712296,287.591248 164.992676,291.067230 165.062637,295.256683 
	C165.144150,300.139221 161.861786,298.794006 159.281693,298.995697 
	C156.942001,299.178589 153.591675,298.177124 154.431320,303.615173 
	C156.235092,302.362305 157.200333,301.161713 158.266266,301.064423 
	C160.338242,300.875305 162.466751,301.305359 164.571838,301.478943 
	C163.715698,303.420288 163.118546,306.833252 161.956726,307.037750 
	C157.828461,307.764282 153.400284,307.944366 149.319199,307.142670 
	C147.922791,306.868347 147.213806,303.094635 146.140045,300.487793 
z"/>
<path fill="#DBDBDB" opacity="1.000000" stroke="none" 
	d="
M187.447113,299.027344 
	C186.385971,299.480621 185.344101,299.829834 185.224548,300.379608 
	C185.058319,301.143951 185.583389,302.058685 185.643524,302.281830 
	C188.956223,301.465149 191.864197,300.748230 194.931702,299.992004 
	C195.070908,307.211121 194.361603,307.968567 188.109100,308.001099 
	C186.276672,308.010651 184.392242,308.254517 182.621216,307.918640 
	C177.324615,306.914215 176.671463,305.594696 177.030945,295.152679 
	C177.171524,291.069489 177.731293,287.348755 182.954407,287.008179 
	C183.949753,286.943268 184.953018,286.998474 185.952621,286.999695 
	C194.922272,287.010498 196.597183,288.938141 195.479355,299.012726 
	C193.024673,299.012726 190.451248,299.012726 187.447113,299.027344 
z"/>
<path fill="#DBDBDB" opacity="1.000000" stroke="none" 
	d="
M302.062836,307.351532 
	C300.821075,305.101013 299.354828,303.139404 299.169769,301.063599 
	C298.776459,296.652863 299.049683,292.182739 299.049683,287.376587 
	C301.789154,287.376587 304.005768,287.376587 307.032043,287.376587 
	C307.032043,292.130280 307.032043,296.767365 307.032043,301.404419 
	C307.591339,301.411774 308.150604,301.419128 308.709900,301.426453 
	C308.709900,296.869537 308.709900,292.312592 308.709900,287.392761 
	C311.792053,287.392761 314.008331,287.392761 316.946960,287.392761 
	C316.946960,293.552521 317.040894,299.636566 316.830872,305.710114 
	C316.803894,306.489990 315.216278,307.832153 314.319580,307.859406 
	C310.359070,307.979767 306.386871,307.714417 302.062836,307.351532 
z"/>
<path fill="#DADADA" opacity="1.000000" stroke="none" 
	d="
M234.945938,292.416016 
	C234.950226,294.035797 234.898651,295.204376 234.958984,296.367157 
	C235.695831,310.567688 231.656540,307.908051 220.904114,307.739258 
	C219.585220,307.718567 217.417114,305.109314 217.190292,303.483704 
	C216.643188,299.562897 216.957047,295.512726 217.054779,291.517944 
	C217.077530,290.587860 217.494720,288.940277 217.947388,288.860931 
	C222.226242,288.111206 226.565308,287.190796 230.856766,287.320465 
	C232.264023,287.363037 233.582825,290.331573 234.945938,292.416016 
M224.036148,294.972992 
	C224.329147,297.540497 224.622162,300.107971 224.915161,302.675476 
	C225.484116,302.590942 226.053085,302.506409 226.622040,302.421844 
	C226.622040,299.122498 226.622040,295.823151 226.622040,291.413605 
	C225.297958,292.868713 224.714783,293.509583 224.036148,294.972992 
z"/>
<path fill="#DADADA" opacity="1.000000" stroke="none" 
	d="
M382.639282,287.002075 
	C386.646545,287.073914 388.086517,289.011078 388.013275,292.432220 
	C387.942169,295.752106 387.863678,299.081696 388.035492,302.394226 
	C388.223602,306.020325 386.253723,307.625031 383.066437,307.921234 
	C380.432800,308.166046 377.757507,307.966888 375.100739,307.952667 
	C374.771362,307.950928 374.400085,307.962280 374.119446,307.823486 
	C366.560211,304.085358 371.095612,297.228607 370.170349,291.829620 
	C369.528717,288.085724 372.728394,287.269836 375.716827,287.057434 
	C377.865509,286.904724 380.032562,287.010132 382.639282,287.002075 
M380.973389,300.133362 
	C380.965302,298.133820 381.106415,296.116364 380.869476,294.144318 
	C380.784790,293.439606 379.785461,292.844818 379.203613,292.199860 
	C378.594391,292.860443 377.475159,293.515869 377.468445,294.182587 
	C377.445129,296.487366 377.492401,298.885132 378.109344,301.079865 
	C378.819214,303.605286 380.064819,303.450348 380.973389,300.133362 
z"/>
<path fill="#DADADA" opacity="1.000000" stroke="none" 
	d="
M210.250885,299.978027 
	C212.090927,300.834473 213.512161,301.694733 214.933380,302.555023 
	C213.465454,304.333344 212.318176,306.994995 210.450760,307.672882 
	C207.953186,308.579498 204.867752,308.218567 202.087906,307.915131 
	C197.691208,307.435150 196.569565,304.199646 197.062393,294.206940 
	C197.247498,290.453735 198.244827,287.195190 202.844711,287.030701 
	C205.489609,286.936127 208.544159,286.297455 210.681015,287.367737 
	C212.820358,288.439270 214.047073,291.332916 215.673798,293.427856 
	C215.260696,293.986847 214.847595,294.545807 214.434494,295.104797 
	C211.507751,294.784149 208.581024,294.463501 205.346466,294.109131 
	C205.346466,296.125519 205.346466,298.650391 205.346466,301.517212 
	C206.944931,300.970062 208.388489,300.475952 210.250885,299.978027 
z"/>
<path fill="#DADADA" opacity="1.000000" stroke="none" 
	d="
M367.210022,306.095886 
	C362.464020,306.816010 357.211731,308.842133 353.663361,307.195740 
	C348.221527,304.670898 351.553772,298.233978 351.201019,293.535645 
	C351.050903,291.535675 352.677490,287.905579 354.090485,287.578064 
	C357.691254,286.743439 361.879639,286.578583 365.321533,287.706360 
	C367.056824,288.274994 367.681305,292.233582 368.808075,294.659271 
	C368.323425,295.029449 367.838776,295.399628 367.354126,295.769775 
	C364.771942,295.084930 362.189728,294.400055 358.569366,293.439850 
	C358.442383,295.986969 358.310486,298.633209 358.174072,301.369476 
	C361.745209,300.741058 364.472961,300.261047 367.982117,299.643524 
	C367.758209,302.300995 367.612610,304.028900 367.210022,306.095886 
z"/>
<path fill="#45B6F6" opacity="1.000000" stroke="none" 
	d="
M240.501007,100.752518 
	C243.367599,107.733765 241.969574,112.400627 236.634293,114.353348 
	C232.627838,115.819717 228.885437,115.082542 226.355423,111.632896 
	C223.902557,108.288422 223.794296,104.261208 226.240692,100.917274 
	C228.709808,97.542259 232.415482,96.522575 236.463425,98.150360 
	C237.818848,98.695404 239.003174,99.665993 240.501007,100.752518 
z"/>
<path fill="#49B7F6" opacity="1.000000" stroke="none" 
	d="
M234.972351,140.842789 
	C235.420853,140.826035 235.869370,140.809265 237.018509,140.869507 
	C238.485687,140.917709 239.252243,140.888885 240.018784,140.860077 
	C240.455017,140.929199 240.891266,140.998322 241.895660,141.312897 
	C243.998993,142.002365 245.534149,142.446381 247.069321,142.890396 
	C247.069321,142.890396 247.039429,142.966736 247.145020,143.167328 
	C247.250610,143.367905 247.606110,143.650269 247.606110,143.650269 
	C247.606110,143.650269 248.059906,143.636002 248.059906,143.636002 
	C251.475891,148.876099 247.995056,156.664139 240.987976,159.458771 
	C240.009140,152.702820 238.599503,151.703934 228.661285,150.595490 
	C230.555161,147.110229 232.763763,143.976517 234.972351,140.842789 
z"/>
<path fill="#DADADA" opacity="1.000000" stroke="none" 
	d="
M167.570221,307.245789 
	C167.204361,298.935333 167.174118,290.965485 167.008331,282.998444 
	C166.945541,279.981018 168.925507,279.580475 170.926636,280.115356 
	C172.409348,280.511719 174.709076,281.912811 174.758408,282.955597 
	C175.095795,290.085480 175.114594,297.245026 174.873764,304.382812 
	C174.697266,309.613861 170.800507,307.820160 167.570221,307.245789 
z"/>
<path fill="#60B7E8" opacity="1.000000" stroke="none" 
	d="
M294.565125,330.523438 
	C295.912659,328.977417 297.090485,327.782104 298.268311,326.586792 
	C298.763214,327.042725 299.258087,327.498688 299.752991,327.954620 
	C295.896454,331.787476 297.712036,339.710907 289.859192,341.326385 
	C289.122986,338.838226 288.583313,337.014191 287.855072,334.553040 
	C286.975189,335.819550 286.614990,336.570526 286.050446,337.105988 
	C284.516693,338.560822 282.896332,339.924347 281.310272,341.324036 
	C280.554199,339.349304 279.656158,337.414398 279.087708,335.387024 
	C278.645264,333.809082 278.431366,332.112762 278.461212,330.475342 
	C278.481995,329.333984 279.087219,328.203247 279.431366,327.067780 
	C280.300079,327.829041 281.295715,328.490448 281.997772,329.382843 
	C282.570099,330.110352 282.791260,331.114105 283.291931,332.284790 
	C284.059418,331.816925 284.769623,331.629272 285.049011,331.176025 
	C288.139832,326.162109 288.071442,326.185730 291.492188,331.134338 
	C291.764496,331.528320 293.395721,330.982971 294.565125,330.523438 
z"/>
<path fill="#65B9EB" opacity="1.000000" stroke="none" 
	d="
M380.994385,327.389160 
	C386.499542,326.324890 388.084198,332.401642 392.330505,333.168274 
	C394.630493,332.453735 397.018555,331.166962 399.444061,331.092072 
	C402.701019,330.991486 403.748627,334.312744 402.073090,337.137299 
	C399.951691,340.713440 397.495605,342.250702 393.570343,340.006165 
	C393.290741,339.846191 392.833496,339.606537 392.654144,339.718079 
	C386.603577,343.479492 387.990051,338.422729 386.626404,334.566895 
	C385.763641,335.546173 384.766876,336.440186 384.074799,337.528076 
	C383.342529,338.679169 382.900299,340.014771 381.319092,340.691406 
	C381.319092,337.116669 381.319092,333.541962 381.156372,329.284790 
	C380.993866,328.197968 380.994110,327.793549 380.994385,327.389160 
z"/>
<path fill="#D9D9D9" opacity="1.000000" stroke="none" 
	d="
M296.251526,281.120239 
	C296.232239,290.113800 296.232239,298.700897 296.232239,307.634247 
	C293.596558,307.634247 291.698334,307.634247 288.995850,307.634247 
	C288.995850,303.173035 288.995850,298.761139 288.995850,294.349243 
	C288.995850,289.883423 288.995850,285.417603 288.995850,279.987579 
	C291.685455,280.256073 293.978149,280.484955 296.251526,281.120239 
z"/>
<path fill="#69BDEA" opacity="1.000000" stroke="none" 
	d="
M52.974857,331.993103 
	C54.239071,331.680389 55.594875,331.558411 56.744263,331.006500 
	C58.172798,330.320618 59.430710,329.279419 60.993858,328.994232 
	C62.083881,330.672302 62.942368,331.747833 63.800850,332.823364 
	C62.971508,335.063599 62.142170,337.303864 61.312832,339.544098 
	C61.663891,339.825562 62.014950,340.106995 62.366013,340.388458 
	C59.931892,341.817169 57.896835,342.264313 56.732018,338.452454 
	C56.507378,337.717285 54.811806,337.431641 53.391273,336.593018 
	C52.446655,335.207611 51.905548,334.167786 51.364445,333.127960 
	C50.830940,333.554230 50.297436,333.980530 49.763931,334.406799 
	C50.829525,335.264648 51.895115,336.122528 52.960701,336.980408 
	C53.333298,341.197144 51.189968,341.939484 47.596241,341.031982 
	C46.017845,340.633392 44.329376,340.670654 42.321381,340.367432 
	C41.686821,339.601440 41.421772,338.978027 41.156719,338.354614 
	C42.217575,337.203674 43.547115,336.201569 44.283936,334.871185 
	C45.869801,332.007751 47.967144,330.486237 51.826698,331.393860 
	C52.517948,331.825104 52.732212,331.936401 52.974857,331.993103 
M49.334549,339.488159 
	C49.881603,339.523987 50.428654,339.559845 50.975708,339.595703 
	C50.980431,339.438629 50.985153,339.281555 50.989880,339.124512 
	C50.340061,339.069275 49.690243,339.014069 48.706509,338.497894 
	C48.146679,338.007416 47.586849,337.516937 47.531067,336.970947 
	C47.787750,336.660187 48.044430,336.349426 48.301105,336.038727 
	C48.022800,335.904144 47.744495,335.769562 47.466202,335.634979 
	C47.301239,336.080261 47.136276,336.525543 46.371803,337.063232 
	C46.092598,337.638306 45.813396,338.213348 45.534199,338.788422 
	C46.684219,338.867218 47.834244,338.946014 49.334549,339.488159 
M48.587280,333.515808 
	C48.587280,333.515808 48.477306,333.581024 48.587280,333.515808 
z"/>
<path fill="#68BBE9" opacity="1.000000" stroke="none" 
	d="
M457.109467,331.069427 
	C459.535736,331.069427 461.961975,331.069427 464.337555,331.069427 
	C465.097015,338.498535 465.097015,338.498535 467.833801,339.257355 
	C466.174713,340.517273 464.263062,342.257477 462.086304,343.551880 
	C459.090729,345.333221 455.898193,346.729309 453.562012,342.569580 
	C455.706207,342.186249 457.464935,342.186249 459.223663,342.186249 
	C453.279755,339.782227 452.535004,337.658478 455.886871,331.913818 
	C455.886871,331.913818 455.937531,331.939819 456.189758,331.867462 
	C456.664459,331.553192 456.886963,331.311310 457.109467,331.069427 
z"/>
<path fill="#69BDEA" opacity="1.000000" stroke="none" 
	d="
M323.176300,331.902557 
	C326.432465,330.876160 330.782318,332.507111 332.625244,327.935303 
	C332.812531,327.470734 334.104523,327.064056 334.671326,327.256531 
	C335.168823,327.425415 335.707733,328.510040 335.641357,329.127014 
	C335.307434,332.229706 334.966431,335.354004 334.273285,338.389771 
	C333.480469,341.862030 329.195190,342.371002 323.274963,339.163361 
	C323.060364,336.300140 323.118317,334.101349 323.176300,331.902557 
z"/>
<path fill="#65B9EB" opacity="1.000000" stroke="none" 
	d="
M248.993774,330.006439 
	C245.723648,329.442841 244.834106,331.075623 244.282608,334.099152 
	C243.843903,336.504333 242.061554,338.664459 240.875702,340.933380 
	C240.665710,338.703125 240.636902,336.436157 240.194061,334.253143 
	C239.763733,332.131805 238.908844,330.096588 238.209732,327.928497 
	C242.983826,325.606415 249.575378,326.566620 253.544083,330.313019 
	C254.450867,331.169006 255.763397,331.595154 256.787659,332.691101 
	C256.145264,333.766327 255.604416,334.369598 255.063583,334.972839 
	C253.960144,335.253235 252.856705,335.533630 251.282318,335.753357 
	C249.976944,336.103607 249.142548,336.514526 248.308151,336.925446 
	C248.475952,335.823059 248.643753,334.720642 249.191010,333.110992 
	C250.341980,331.350677 251.113510,330.097656 251.885025,328.844604 
	C251.616776,328.617798 251.348526,328.391022 251.080276,328.164215 
	C250.384781,328.778290 249.689270,329.392365 248.993774,330.006439 
z"/>
<path fill="#69BDEA" opacity="1.000000" stroke="none" 
	d="
M125.797546,338.949524 
	C125.397781,338.003357 124.998016,337.057220 124.229836,335.239075 
	C123.221817,337.664764 122.575539,339.219971 121.839455,340.991302 
	C120.187897,340.809784 118.591499,340.634338 116.753990,340.122162 
	C116.916016,337.570404 117.319153,335.355408 117.801582,332.704681 
	C115.129021,332.452240 112.870270,332.238892 110.611526,332.025513 
	C116.618004,331.985474 122.625748,331.883667 128.628906,332.006134 
	C129.196472,332.017700 129.735321,333.437592 130.655579,334.587646 
	C131.016647,335.642426 131.009628,336.313293 130.666595,337.025208 
	C128.819580,337.694061 127.308563,338.321777 125.797546,338.949524 
z"/>
<path fill="#D6F7FE" opacity="1.000000" stroke="none" 
	d="
M183.001373,223.350250 
	C183.475143,222.982361 183.864838,222.974625 184.794037,223.233002 
	C186.569061,222.995941 187.804611,222.492752 189.040161,221.989548 
	C190.118652,221.660751 191.197159,221.331970 192.618317,221.003723 
	C198.944107,223.213074 203.852402,220.143204 208.973938,217.399597 
	C209.450211,217.018799 209.852783,216.997070 210.779633,217.228516 
	C212.219452,216.989929 213.135010,216.498184 214.050552,216.006439 
	C216.033279,215.650681 218.016022,215.294922 220.769547,214.961884 
	C222.395020,214.316986 223.249710,213.649368 224.104401,212.981750 
	C224.478973,212.977219 224.853561,212.972687 225.504745,212.970978 
	C228.894714,212.304611 232.008087,211.635422 235.847748,210.987228 
	C236.792236,211.043274 237.010437,211.078339 237.228653,211.113434 
	C222.974884,215.377457 208.772964,219.827972 194.441742,223.813553 
	C188.725342,225.403305 182.723343,225.966064 176.430939,226.938477 
	C178.312805,225.823883 180.615067,224.767136 183.001373,223.350250 
z"/>
<path fill="#68BBE9" opacity="1.000000" stroke="none" 
	d="
M97.020416,338.136414 
	C94.787773,339.103363 92.624321,340.309204 90.306107,340.980743 
	C86.910004,341.964508 84.298828,340.602997 83.364189,337.176483 
	C82.199341,332.905914 84.030663,329.432343 87.809891,327.661682 
	C90.482178,326.409668 93.847313,325.790527 96.359978,330.319916 
	C94.087555,330.488831 92.098915,330.088257 90.846069,330.868774 
	C89.285469,331.841064 88.331856,333.787598 87.116142,335.313446 
	C88.596985,335.877594 90.039062,336.615387 91.569565,336.956970 
	C93.091576,337.296692 94.702446,337.238312 96.631134,337.431396 
	C96.959198,337.719757 96.970032,337.929108 97.020416,338.136414 
z"/>
<path fill="#72C0EC" opacity="1.000000" stroke="none" 
	d="
M97.130615,338.526520 
	C96.970032,337.929108 96.959198,337.719757 97.013405,337.348633 
	C98.286308,335.109680 99.094643,332.094482 100.916542,331.239807 
	C102.443802,330.523315 105.167313,332.289886 107.334023,333.035828 
	C108.220604,333.341003 109.035767,333.853607 110.318184,334.232422 
	C110.752953,334.193024 110.783768,334.258026 110.783768,334.258026 
	C109.108734,340.635345 101.791008,343.152557 97.130615,338.526520 
z"/>
<path fill="#68BBE9" opacity="1.000000" stroke="none" 
	d="
M439.967041,340.954834 
	C438.600494,339.481842 437.233948,338.008850 435.360840,335.989838 
	C432.845917,336.289581 433.194946,343.865540 427.335663,340.108978 
	C427.237579,339.081818 427.087982,337.514862 426.902161,335.192810 
	C426.627106,333.355316 426.388275,332.272919 426.149414,331.190552 
	C427.941956,332.159790 429.734467,333.128998 430.229004,333.396423 
	C433.430450,332.515076 435.808990,331.860291 438.993164,331.176758 
	C439.854889,334.416962 439.910980,337.685913 439.967041,340.954834 
z"/>
<path fill="#76C5ED" opacity="1.000000" stroke="none" 
	d="
M192.025101,338.921814 
	C190.685806,339.365387 189.374054,340.031128 188.000870,340.201355 
	C185.854202,340.467438 183.374924,341.038208 181.603561,340.232819 
	C180.526138,339.742981 179.589371,336.385712 180.233994,335.249481 
	C181.272400,333.419067 183.494781,331.766785 185.547806,331.161743 
	C187.187653,330.678497 189.320084,331.866669 191.652618,332.714691 
	C192.055954,335.051544 192.040527,336.986694 192.025101,338.921814 
z"/>
<path fill="#65B9EB" opacity="1.000000" stroke="none" 
	d="
M171.635727,327.559784 
	C173.072479,327.845032 174.284729,328.036224 175.441101,327.860199 
	C176.774139,327.657288 178.057816,327.130157 179.558411,326.685974 
	C177.399384,331.568420 175.648026,335.703491 173.707230,339.747681 
	C173.380676,340.428131 172.273575,340.923767 171.448441,341.074799 
	C171.149796,341.129456 170.433243,339.979614 170.229080,339.284912 
	C169.344498,336.275055 168.523132,333.243439 167.804184,330.190186 
	C167.590836,329.284180 167.574615,327.526886 167.832687,327.454193 
	C168.943344,327.141388 170.189636,327.310089 171.635727,327.559784 
z"/>
<path fill="#E2F9FD" opacity="1.000000" stroke="none" 
	d="
M237.584442,211.072357 
	C237.010437,211.078339 236.792236,211.043274 236.244675,210.962952 
	C235.940186,209.898285 235.965073,208.878891 235.979980,207.479019 
	C241.729019,205.093567 247.488022,203.088577 253.611099,201.042755 
	C257.890900,199.198715 261.806610,197.395508 265.872498,195.271194 
	C267.461273,194.040115 268.899902,193.130142 270.654388,192.465942 
	C270.988403,193.492981 271.006592,194.274261 271.024780,195.055542 
	C271.024780,195.055542 271.077332,195.025711 270.758179,195.050369 
	C269.945465,195.446838 269.451935,195.818634 268.958374,196.190445 
	C268.958374,196.190445 268.729950,196.305130 268.292267,196.278839 
	C267.552429,196.575882 267.250275,196.899216 266.948151,197.222549 
	C257.278839,201.825470 247.609543,206.428375 237.584442,211.072357 
z"/>
<path fill="#76C5ED" opacity="1.000000" stroke="none" 
	d="
M310.011169,339.969635 
	C308.764404,340.243530 307.477905,340.401855 306.278748,340.814240 
	C303.353485,341.820251 300.194580,342.032562 299.425507,338.592529 
	C298.967255,336.542816 300.036346,333.011414 301.634308,331.910553 
	C304.005096,330.277344 307.595673,329.573547 309.509705,333.534241 
	C309.721130,333.971741 310.044556,334.355103 310.687836,335.260376 
	C310.710144,337.161316 310.360657,338.565460 310.011169,339.969635 
z"/>
<path fill="#68BBE9" opacity="1.000000" stroke="none" 
	d="
M350.036652,327.135162 
	C352.265411,328.203430 354.494202,329.271729 356.706573,330.332153 
	C358.712097,329.191528 361.168457,327.794495 363.624847,326.397461 
	C364.068390,326.838989 364.511932,327.280487 364.955444,327.721985 
	C363.323181,331.948944 362.588654,336.948273 359.804596,340.184265 
	C357.054321,343.381042 357.551605,338.159302 355.895203,336.536774 
	C354.679230,335.533447 353.694855,335.244629 352.735596,334.614563 
	C352.516266,333.180420 352.271851,332.087494 352.279572,330.792053 
	C351.700043,329.438049 350.868347,328.286591 350.036652,327.135162 
z"/>
<path fill="#72C0EC" opacity="1.000000" stroke="none" 
	d="
M228.361954,339.706604 
	C225.499969,339.853058 222.795135,340.117859 220.400650,339.378113 
	C219.746384,339.175964 219.206650,335.123840 220.047363,334.298859 
	C221.709061,332.668243 224.355530,331.249023 226.623047,331.205292 
	C230.452805,331.131409 231.240082,334.859650 228.361954,339.706604 
z"/>
<path fill="#D6F7FE" opacity="1.000000" stroke="none" 
	d="
M173.729492,226.718842 
	C166.954590,228.148849 159.909012,229.295776 152.863449,230.442719 
	C152.790512,230.719101 152.717590,230.995483 152.644653,231.271866 
	C155.901855,232.031097 159.159058,232.790314 162.703125,233.796066 
	C156.347412,234.765106 150.046234,233.960953 144.308441,230.637405 
	C144.313232,230.001602 144.318039,229.365784 144.322830,228.729980 
	C154.034836,227.965240 163.746841,227.200500 173.729492,226.718842 
z"/>
<path fill="#69BDEA" opacity="1.000000" stroke="none" 
	d="
M209.286011,328.060150 
	C212.950104,327.333435 216.539429,326.960358 219.338959,326.669373 
	C217.617874,331.460571 216.301132,335.549530 214.617325,339.481262 
	C214.249878,340.339203 212.570908,340.635498 210.873032,341.512054 
	C210.805099,336.401398 213.469650,331.853210 209.286011,328.060150 
z"/>
<path fill="#D6F7FE" opacity="1.000000" stroke="none" 
	d="
M262.218506,224.894409 
	C260.086243,223.910660 258.291840,222.910172 255.717117,221.474609 
	C263.139709,218.346146 269.135529,213.300827 277.479431,213.834656 
	C282.930267,214.183395 288.426361,213.824982 294.350220,213.711151 
	C293.490997,214.417175 292.212006,215.804504 290.871368,215.866943 
	C285.394104,216.121979 279.897034,215.915955 274.409332,216.006256 
	C272.785736,216.032974 271.119507,216.305023 269.558746,216.759613 
	C265.518616,217.936325 262.720764,220.302017 262.218506,224.894409 
z"/>
<path fill="#D6F7FE" opacity="1.000000" stroke="none" 
	d="
M227.472717,233.761673 
	C226.978455,228.497421 227.495377,228.069870 234.874023,228.435211 
	C234.874023,238.948318 234.874023,249.519730 234.880005,260.562073 
	C234.263794,259.556641 233.142746,258.093201 233.104004,256.601654 
	C232.915726,249.358139 233.095367,242.105545 232.952133,234.859970 
	C232.922806,233.376373 232.068451,231.909103 231.594391,230.434326 
	C230.298920,231.467926 229.003448,232.501526 227.472717,233.761673 
z"/>
<path fill="#60B7E8" opacity="1.000000" stroke="none" 
	d="
M380.564209,327.232788 
	C380.994110,327.793549 380.993866,328.197968 381.037170,328.783417 
	C377.838593,330.385864 376.766907,332.919769 376.627228,336.374878 
	C376.558960,338.063446 375.200592,339.699890 374.426117,341.359924 
	C374.016541,341.151520 373.606964,340.943085 373.197388,340.734680 
	C372.815765,336.510345 372.434113,332.286041 371.963501,327.076447 
	C373.896393,327.076447 377.015198,327.076447 380.564209,327.232788 
z"/>
<path fill="#72C0EC" opacity="1.000000" stroke="none" 
	d="
M139.949081,333.188507 
	C141.436447,332.732239 142.898224,332.039703 144.417191,331.875183 
	C146.676559,331.630493 148.976334,331.758972 151.461029,332.257416 
	C151.015274,333.172089 150.387924,333.599060 149.715256,333.935425 
	C148.279282,334.653534 146.819809,335.324646 145.369965,336.015015 
	C146.600235,336.690338 147.999420,337.184326 148.995117,338.111542 
	C149.559525,338.637146 149.462357,339.873230 149.663147,340.789307 
	C144.960327,342.999542 142.926804,339.600677 140.346466,336.180481 
	C139.956543,334.943878 139.952820,334.066193 139.949081,333.188507 
z"/>
<path fill="#60B7E8" opacity="1.000000" stroke="none" 
	d="
M192.106644,339.310547 
	C192.040527,336.986694 192.055954,335.051544 192.074280,332.706909 
	C193.678162,330.498779 194.772568,330.369446 195.066467,333.163727 
	C195.224976,334.670929 195.471939,336.168823 195.681793,337.691284 
	C199.923676,336.979370 195.339722,329.694122 202.055008,331.302032 
	C201.620163,334.375549 201.193176,337.393524 200.625061,341.408966 
	C197.608841,340.797729 194.898514,340.248505 192.106644,339.310547 
z"/>
<path fill="#68BBE9" opacity="1.000000" stroke="none" 
	d="
M323.147766,331.564850 
	C323.118317,334.101349 323.060364,336.300140 323.010376,338.832886 
	C321.973511,339.881134 320.928711,340.595459 319.299438,341.709381 
	C319.080475,339.095825 318.911316,337.076691 318.661987,334.100983 
	C316.882446,335.001923 315.597809,335.652283 313.802368,336.316467 
	C313.190125,334.612854 313.088715,332.895386 312.987305,331.177948 
	C314.043488,331.155487 315.099670,331.132996 316.375122,331.375427 
	C316.255371,332.230316 315.916290,332.820221 315.577209,333.410126 
	C316.397247,332.920166 317.217316,332.430206 318.037384,331.940247 
	C318.794189,331.003662 319.512878,330.032654 320.318451,329.140106 
	C321.126770,328.244537 322.021759,327.427216 323.854828,327.217987 
	C323.609650,328.554382 323.364471,329.890778 323.147766,331.564850 
z"/>
<path fill="#68BBE9" opacity="1.000000" stroke="none" 
	d="
M152.934814,331.981506 
	C153.866486,330.632843 154.798157,329.284180 156.016846,327.520020 
	C157.531143,328.932648 158.987518,330.291260 160.762939,331.947479 
	C157.869553,334.142761 156.330612,336.640900 160.314667,340.526764 
	C157.564957,340.729309 156.244354,340.826569 154.516418,340.474609 
	C153.717651,337.344055 153.326233,334.662781 152.934814,331.981506 
z"/>
<path fill="#65B9EB" opacity="1.000000" stroke="none" 
	d="
M444.049622,331.045654 
	C446.430298,331.262207 448.810944,331.478760 451.550354,332.354248 
	C451.818665,335.533325 451.728210,338.053406 451.637726,340.573517 
	C450.979492,340.724152 450.321289,340.874817 449.285889,340.573181 
	C448.908752,338.124908 448.908752,336.128937 448.908752,334.132935 
	C448.217346,334.016937 447.525940,333.900940 446.834564,333.784943 
	C446.196930,336.167236 445.559265,338.549561 444.921631,340.931854 
	C444.921661,340.931854 444.992584,341.002319 444.639923,340.971527 
	C443.856567,340.985291 443.425903,341.029846 442.995209,341.074402 
	C442.995209,341.074371 442.645874,340.891357 442.468933,340.459900 
	C442.877899,337.034210 443.463745,334.039917 444.049622,331.045654 
z"/>
<path fill="#68BBE9" opacity="1.000000" stroke="none" 
	d="
M418.927521,340.959412 
	C418.192932,341.007812 417.458374,341.056183 415.947449,341.086029 
	C413.784668,341.091827 412.398193,341.116150 411.011749,341.140503 
	C411.011749,341.140503 410.573395,341.010223 410.325317,340.639160 
	C410.024841,339.835266 409.972443,339.402435 409.920013,338.969635 
	C409.972046,338.540070 410.024048,338.110504 410.346344,337.103394 
	C410.708832,335.345612 410.801056,334.165344 410.893280,332.985107 
	C410.988617,331.994781 411.083954,331.004456 411.404968,329.421570 
	C411.767303,328.227814 411.903900,327.626556 412.040527,327.025330 
	C412.040527,327.025330 411.996582,327.007080 412.373535,327.015381 
	C413.464813,327.063568 414.179169,327.103485 414.893494,327.143402 
	C414.893616,328.480591 414.893707,329.817780 414.650635,331.863434 
	C414.251770,334.162537 414.096100,335.753174 414.076080,335.957703 
	C416.270599,338.220215 417.599060,339.589813 418.927521,340.959412 
z"/>
<path fill="#68BBE9" opacity="1.000000" stroke="none" 
	d="
M40.688793,338.239502 
	C41.421772,338.978027 41.686821,339.601440 41.972660,340.480438 
	C39.280174,340.896027 36.566898,341.056061 33.155464,341.257263 
	C33.934658,336.858612 34.562847,333.312408 35.692959,329.382538 
	C36.886040,328.924286 37.577194,328.849701 38.268349,328.775116 
	C38.268349,328.775116 38.260975,328.833710 38.162987,329.236145 
	C38.035206,330.730804 38.005413,331.822998 37.975616,332.915192 
	C37.999657,334.235748 38.023701,335.556335 38.184677,337.333557 
	C38.321613,337.790222 38.316456,337.842407 38.316456,337.842407 
	C38.951260,337.936401 39.586063,338.030396 40.688793,338.239502 
z"/>
<path fill="#D6F7FE" opacity="1.000000" stroke="none" 
	d="
M173.229706,235.363770 
	C175.332611,235.970993 178.081985,236.299820 179.916290,237.770187 
	C185.702286,242.408279 191.145203,247.474365 196.881287,252.669342 
	C188.888107,251.680145 184.556686,245.075577 179.050201,240.088638 
	C177.282837,238.488037 175.327011,237.095505 173.229706,235.363770 
z"/>
<path fill="#C5F1FD" opacity="1.000000" stroke="none" 
	d="
M64.180771,332.902283 
	C62.942368,331.747833 62.083881,330.672302 61.167015,329.284668 
	C61.604847,328.966827 62.346317,329.172668 62.560825,328.923981 
	C66.331795,324.552032 68.743393,326.525543 70.690674,331.209290 
	C70.532455,334.020264 70.429085,336.225861 70.304726,338.879333 
	C70.312218,338.878815 69.191963,338.954163 68.071701,339.029480 
	C66.901367,337.013397 65.731026,334.997314 64.180771,332.902283 
z"/>
<path fill="#5EB6E5" opacity="1.000000" stroke="none" 
	d="
M68.149170,339.399384 
	C69.191963,338.954163 70.312218,338.878815 70.304726,338.879333 
	C70.429085,336.225861 70.532455,334.020264 70.819832,331.541870 
	C72.494911,331.880219 75.145111,332.331055 75.255966,333.132935 
	C75.567154,335.383911 75.467957,338.234589 74.252464,339.935181 
	C73.599464,340.848816 70.320633,339.885620 68.149170,339.399384 
z"/>
<path fill="#65B9EB" opacity="1.000000" stroke="none" 
	d="
M266.981873,331.235809 
	C272.624542,333.884521 268.559875,337.173004 267.269470,340.062256 
	C265.699951,339.116547 264.520355,338.100098 263.156586,337.676361 
	C260.751617,336.929077 257.781433,337.091156 257.981689,333.323975 
	C259.117096,333.070740 260.252472,332.817505 261.791992,332.759033 
	C263.791382,332.381134 265.386627,331.808472 266.981873,331.235809 
z"/>
<path fill="#D6F7FE" opacity="1.000000" stroke="none" 
	d="
M203.851624,250.935974 
	C200.744247,245.987564 197.697098,240.641479 194.629257,235.259125 
	C201.901810,231.597580 202.309769,231.790436 205.009766,239.619934 
	C204.030670,239.034027 203.110611,237.988464 202.041260,237.128571 
	C201.127136,236.393509 200.041779,235.871445 199.032150,235.255173 
	C198.916870,236.538788 198.345642,238.002151 198.768112,239.073776 
	C200.302628,242.966263 202.169983,246.727524 203.851624,250.935974 
z"/>
<path fill="#68BBE9" opacity="1.000000" stroke="none" 
	d="
M352.816406,338.982941 
	C349.583618,342.234985 346.228546,342.473938 342.975220,339.131042 
	C340.114227,336.191284 340.687683,332.620178 344.725159,329.085236 
	C345.431000,329.908905 345.822876,330.499420 346.086060,331.344971 
	C345.630341,332.067047 345.303253,332.534058 344.643494,333.048309 
	C344.523529,334.061554 344.736237,335.027557 345.078857,336.203796 
	C345.494049,336.815125 345.779358,337.216248 345.860016,337.859497 
	C345.884460,338.742615 346.113586,339.383667 346.342682,340.024689 
	C346.626892,339.892609 346.911072,339.760559 347.195282,339.628479 
	C346.824158,338.991241 346.453033,338.354004 346.446320,337.739563 
	C348.207520,337.520081 349.604340,337.277832 351.035492,337.338684 
	C351.652039,338.088867 352.234222,338.535889 352.816406,338.982941 
z"/>
<path fill="#DBDBDB" opacity="1.000000" stroke="none" 
	d="
M340.426514,302.552002 
	C342.761566,301.684814 344.907532,301.196625 347.589966,300.586426 
	C347.589966,303.318665 347.589966,305.216766 347.589966,307.557129 
	C345.412170,307.557129 343.187347,307.557129 340.237488,307.557129 
	C340.237488,306.065796 340.237488,304.498444 340.426514,302.552002 
z"/>
<path fill="#D6F7FE" opacity="1.000000" stroke="none" 
	d="
M302.271729,193.953247 
	C307.384888,187.381638 312.869385,180.831863 318.640015,174.105743 
	C317.595551,176.979721 316.890198,180.581284 314.795135,182.957611 
	C311.201813,187.033325 306.740143,190.343506 302.271729,193.953247 
z"/>
<path fill="#65B9EB" opacity="1.000000" stroke="none" 
	d="
M139.845276,332.876831 
	C139.952820,334.066193 139.956543,334.943878 139.958344,336.145813 
	C139.828064,336.606262 139.699707,336.742462 138.906982,336.933228 
	C137.050201,337.415833 135.856354,338.182892 134.665756,338.177887 
	C133.443054,338.172791 132.223541,337.413422 131.002625,336.984131 
	C131.009628,336.313293 131.016647,335.642426 131.041016,334.640259 
	C131.232437,333.980377 131.406525,333.651855 132.067535,333.264221 
	C133.055725,332.649323 133.557007,332.093567 134.058289,331.537811 
	C135.132874,331.394928 136.207474,331.252045 137.795868,331.374847 
	C138.646500,331.780731 138.983322,331.920929 139.320145,332.061096 
	C139.460602,332.229126 139.601044,332.397156 139.845276,332.876831 
z"/>
<path fill="#5EB6E5" opacity="1.000000" stroke="none" 
	d="
M257.816925,333.169067 
	C257.781433,337.091156 260.751617,336.929077 263.156586,337.676361 
	C264.520355,338.100098 265.699951,339.116547 266.974182,340.167603 
	C263.151245,340.286713 259.316559,340.104370 255.078552,339.902832 
	C255.078552,338.370483 255.078552,337.109497 255.071075,335.410645 
	C255.604416,334.369598 256.145264,333.766327 256.956268,333.012268 
	C257.226440,332.861481 257.652161,333.014160 257.816925,333.169067 
z"/>
<path fill="#E2F9FD" opacity="1.000000" stroke="none" 
	d="
M152.653381,331.999390 
	C153.326233,334.662781 153.717651,337.344055 154.201660,340.432831 
	C153.059753,340.867523 151.825256,340.894714 150.126953,340.855591 
	C149.462357,339.873230 149.559525,338.637146 148.995117,338.111542 
	C147.999420,337.184326 146.600235,336.690338 145.369965,336.015015 
	C146.819809,335.324646 148.279282,334.653534 149.715256,333.935425 
	C150.387924,333.599060 151.015274,333.172089 151.738358,332.441406 
	C151.999680,332.070190 152.185806,332.043701 152.653381,331.999390 
z"/>
<path fill="#72C0EC" opacity="1.000000" stroke="none" 
	d="
M130.666611,337.025208 
	C132.223541,337.413422 133.443054,338.172791 134.665756,338.177887 
	C135.856354,338.182892 137.050201,337.415833 138.634369,337.000092 
	C138.740524,338.266541 138.723526,339.694153 138.067917,340.709290 
	C137.679153,341.311279 136.231873,341.587891 135.349121,341.448761 
	C132.024048,340.924805 128.735336,340.170288 125.615273,339.225800 
	C127.308563,338.321777 128.819580,337.694061 130.666611,337.025208 
z"/>
<path fill="#D6F7FE" opacity="1.000000" stroke="none" 
	d="
M321.676453,167.952942 
	C322.344513,161.428421 323.016205,154.832306 318.965332,148.432831 
	C319.654419,148.333313 320.667999,148.375946 321.097229,148.898544 
	C324.536072,153.085190 324.975098,163.334427 321.676453,167.952942 
z"/>
<path fill="#60B7E8" opacity="1.000000" stroke="none" 
	d="
M111.140068,334.534760 
	C110.783768,334.258026 110.752953,334.193024 110.737267,334.193542 
	C110.530663,333.703552 110.339752,333.212982 110.380188,332.373993 
	C112.870270,332.238892 115.129021,332.452240 117.801582,332.704681 
	C117.319153,335.355408 116.916016,337.570404 116.343185,340.036621 
	C116.173492,340.287811 115.954674,340.673584 115.672882,340.807068 
	C115.391098,340.940552 115.197556,340.956726 115.197556,340.956726 
	C114.783859,339.761108 114.539696,338.464813 113.906975,337.399261 
	C113.318840,336.408783 112.316406,335.664276 111.140068,334.534760 
z"/>
<path fill="#C5F1FD" opacity="1.000000" stroke="none" 
	d="
M234.836838,140.553345 
	C232.763763,143.976517 230.555161,147.110229 228.255295,150.557465 
	C227.385452,150.912094 226.606873,150.953232 225.439133,151.010635 
	C227.788574,146.737030 230.527191,142.447128 233.576202,138.013443 
	C234.158173,138.667740 234.429749,139.465805 234.836838,140.553345 
z"/>
<path fill="#C5F1FD" opacity="1.000000" stroke="none" 
	d="
M451.959412,340.793976 
	C451.728210,338.053406 451.818665,335.533325 451.854736,332.590851 
	C452.891937,332.098175 453.983490,332.027863 455.480957,331.935669 
	C452.535004,337.658478 453.279755,339.782227 459.223663,342.186249 
	C457.464935,342.186249 455.706207,342.186249 453.608948,342.193481 
	C452.940613,341.805298 452.610840,341.409851 451.959412,340.793976 
z"/>
<path fill="#D6F7FE" opacity="1.000000" stroke="none" 
	d="
M282.007019,184.879150 
	C282.107025,184.605576 282.275360,184.383957 282.701477,184.126343 
	C286.037079,182.361313 289.183228,180.684250 292.701599,179.023804 
	C289.622345,182.018005 286.170898,184.995605 282.362122,187.623505 
	C282.005493,186.475601 282.006287,185.677383 282.007019,184.879150 
z"/>
<path fill="#C5F1FD" opacity="1.000000" stroke="none" 
	d="
M443.696838,330.965790 
	C443.463745,334.039917 442.877899,337.034210 442.282776,340.394897 
	C441.762390,340.835297 441.251190,340.909271 440.353546,340.969055 
	C439.910980,337.685913 439.854889,334.416962 439.398987,331.087646 
	C439.373840,330.092804 439.748474,329.158356 440.123108,328.223907 
	C441.196777,329.111237 442.270416,329.998596 443.696838,330.965790 
z"/>
<path fill="#D6F7FE" opacity="1.000000" stroke="none" 
	d="
M303.703461,166.058105 
	C301.258820,169.639709 298.462555,173.232681 295.351013,176.945282 
	C297.807800,173.399765 300.579803,169.734619 303.703461,166.058105 
z"/>
<path fill="#68BBE9" opacity="1.000000" stroke="none" 
	d="
M424.115997,335.065338 
	C424.065704,335.492554 424.015411,335.919800 423.708984,336.903015 
	C423.357300,338.331543 423.261749,339.204010 423.166199,340.076508 
	C422.409851,340.370636 421.653473,340.664764 420.532837,340.534241 
	C420.385254,338.385284 420.601990,336.660980 420.818756,334.936707 
	C420.900055,334.231689 420.981354,333.526703 421.374268,332.279297 
	C425.927277,330.341064 423.085754,334.116608 424.115997,335.065338 
z"/>
<path fill="#D6F7FE" opacity="1.000000" stroke="none" 
	d="
M235.032425,261.427063 
	C238.755493,259.134583 242.539520,257.170258 246.636993,255.075272 
	C245.975784,260.068909 240.818878,263.031036 235.032425,261.427063 
z"/>
<path fill="#D6F7FE" opacity="1.000000" stroke="none" 
	d="
M420.468384,335.008636 
	C420.601990,336.660980 420.385254,338.385284 420.205597,340.480896 
	C420.024994,340.887299 419.807281,340.922394 419.258545,340.958466 
	C417.599060,339.589813 416.270599,338.220215 414.076080,335.957703 
	C414.096100,335.753174 414.251770,334.162537 414.648376,332.265259 
	C416.297028,333.560638 416.195251,337.690887 420.468384,335.008636 
z"/>
<path fill="#5EB6E5" opacity="1.000000" stroke="none" 
	d="
M312.631714,331.204468 
	C313.088715,332.895386 313.190125,334.612854 313.525269,336.602081 
	C313.633575,339.063232 314.234894,341.914917 310.164764,340.309540 
	C310.360657,338.565460 310.710144,337.161316 311.031921,335.386597 
	C311.428162,333.754364 311.852112,332.492706 312.631714,331.204468 
z"/>
<path fill="#C5F1FD" opacity="1.000000" stroke="none" 
	d="
M424.450012,335.040161 
	C423.085754,334.116608 425.927277,330.341064 421.389404,331.924133 
	C421.092987,330.527985 421.092987,328.944672 421.092987,327.361328 
	C421.657776,327.133118 422.222565,326.904907 422.787354,326.676697 
	C423.713348,328.022095 424.639374,329.367462 425.857391,330.951721 
	C426.388275,332.272919 426.627106,333.355316 426.826599,334.773865 
	C426.119537,335.078369 425.451782,335.046692 424.450012,335.040161 
z"/>
<path fill="#D6F7FE" opacity="1.000000" stroke="none" 
	d="
M252.209335,248.873337 
	C253.631378,245.266876 255.424576,241.670715 257.475342,237.833466 
	C258.799408,242.626511 255.686844,245.753662 252.209335,248.873337 
z"/>
<path fill="#D6F7FE" opacity="1.000000" stroke="none" 
	d="
M445.328369,340.954712 
	C445.559265,338.549561 446.196930,336.167236 446.834564,333.784943 
	C447.525940,333.900940 448.217346,334.016937 448.908752,334.132935 
	C448.908752,336.128937 448.908752,338.124908 448.946350,340.555908 
	C447.901001,340.986481 446.818054,340.982025 445.328369,340.954712 
z"/>
<path fill="#D6F7FE" opacity="1.000000" stroke="none" 
	d="
M216.987122,257.413696 
	C220.056412,258.352997 223.132004,259.670624 226.591187,260.974792 
	C224.024689,263.684570 218.982666,262.083649 216.987122,257.413696 
z"/>
<path fill="#60B7E8" opacity="1.000000" stroke="none" 
	d="
M346.214752,331.089905 
	C345.822876,330.499420 345.431000,329.908905 345.030151,329.042816 
	C345.177917,328.594849 345.334656,328.422424 346.077026,328.124207 
	C347.085632,327.756592 347.508636,327.514832 347.931671,327.273071 
	C348.397614,327.194550 348.863556,327.116028 349.683075,327.086334 
	C350.868347,328.286591 351.700043,329.438049 351.966248,330.637848 
	C349.672089,330.820770 347.943420,330.955322 346.214752,331.089905 
z"/>
<path fill="#D6F7FE" opacity="1.000000" stroke="none" 
	d="
M258.296997,236.919128 
	C259.074707,233.114594 260.220612,229.256943 261.636597,225.159943 
	C260.826141,228.902420 259.745667,232.884216 258.296997,236.919128 
z"/>
<path fill="#D6F7FE" opacity="1.000000" stroke="none" 
	d="
M318.955811,147.702225 
	C316.371521,145.929810 313.815674,143.782669 311.102173,141.342560 
	C315.070435,141.290375 318.020691,143.036819 318.955811,147.702225 
z"/>
<path fill="#D6F7FE" opacity="1.000000" stroke="none" 
	d="
M310.931824,140.688843 
	C308.716156,140.479355 306.476685,139.895920 304.188141,139.578857 
	C303.495026,139.331879 302.851013,138.818512 302.207001,138.305145 
	C302.802185,137.883652 303.590454,136.973602 303.961151,137.120102 
	C306.327972,138.055557 308.602905,139.223495 310.931824,140.688843 
z"/>
<path fill="#D6F7FE" opacity="1.000000" stroke="none" 
	d="
M307.636475,155.082153 
	C306.882416,158.557419 305.757141,162.106552 304.340332,165.850159 
	C305.120911,162.415085 306.193054,158.785553 307.636475,155.082153 
z"/>
<path fill="#D6F7FE" opacity="1.000000" stroke="none" 
	d="
M306.143372,144.109894 
	C306.989502,146.614685 307.771820,149.556259 308.328247,152.759445 
	C307.428070,150.196259 306.753815,147.371460 306.143372,144.109894 
z"/>
<path fill="#D6F7FE" opacity="1.000000" stroke="none" 
	d="
M295.439697,212.940872 
	C295.543915,210.522507 296.001312,208.031769 296.704132,205.264465 
	C296.563995,207.614777 296.178436,210.241653 295.439697,212.940872 
z"/>
<path fill="#5EB6E5" opacity="1.000000" stroke="none" 
	d="
M248.219543,337.014343 
	C249.142548,336.514526 249.976944,336.103607 250.966599,335.935608 
	C251.040955,337.199371 250.960068,338.220215 250.437531,339.604797 
	C249.370880,339.097046 248.745865,338.225555 248.120850,337.354095 
	C248.120850,337.354095 248.130951,337.103271 248.219543,337.014343 
z"/>
<path fill="#5EB6E5" opacity="1.000000" stroke="none" 
	d="
M266.600952,331.132111 
	C265.386627,331.808472 263.791382,332.381134 262.138062,332.692993 
	C263.460022,331.964233 264.840057,331.496307 266.600952,331.132111 
z"/>
<path fill="#D6F7FE" opacity="1.000000" stroke="none" 
	d="
M208.893005,248.123123 
	C210.112747,249.072113 211.062820,250.193756 212.050537,251.635162 
	C210.933258,250.735214 209.778290,249.515488 208.893005,248.123123 
z"/>
<path fill="#C5F1FD" opacity="1.000000" stroke="none" 
	d="
M247.000214,142.565094 
	C245.534149,142.446381 243.998993,142.002365 242.227310,141.317490 
	C243.637573,141.464355 245.284332,141.852066 247.000214,142.565094 
z"/>
<path fill="#D6F7FE" opacity="1.000000" stroke="none" 
	d="
M297.405640,200.780212 
	C297.857635,199.347382 298.549957,197.700394 299.600006,195.998917 
	C299.187164,197.484970 298.416565,199.025513 297.405640,200.780212 
z"/>
<path fill="#D6F7FE" opacity="1.000000" stroke="none" 
	d="
M205.028625,256.026306 
	C204.715118,254.918320 204.401611,253.810349 204.067535,252.342819 
	C205.042496,253.055161 206.038025,254.127045 206.683014,255.511475 
	C205.880447,255.876312 205.428421,255.928604 204.989578,255.992081 
	C205.002777,256.003265 205.028625,256.026306 205.028625,256.026306 
z"/>
<path fill="#D6F7FE" opacity="1.000000" stroke="none" 
	d="
M201.011871,255.399231 
	C202.083740,255.397736 203.201080,255.733688 204.673523,256.047974 
	C205.028625,256.026306 205.002777,256.003265 204.966248,256.243469 
	C204.955338,256.650024 204.980972,256.816406 205.006607,256.982788 
	C203.690186,256.567413 202.373779,256.152069 201.011871,255.399231 
z"/>
<path fill="#60B7E8" opacity="1.000000" stroke="none" 
	d="
M352.979553,338.726837 
	C352.234222,338.535889 351.652039,338.088867 351.164886,337.259827 
	C351.259918,336.877808 351.562408,336.863953 351.895325,336.765381 
	C352.431976,336.267487 352.635681,335.868164 352.763245,335.351624 
	C352.687103,335.234406 352.710480,334.955811 352.710480,334.955811 
	C353.694855,335.244629 354.679230,335.533447 355.799988,336.201691 
	C355.005127,337.211029 354.073914,337.840881 352.979553,338.726837 
z"/>
<path fill="#5EB6E5" opacity="1.000000" stroke="none" 
	d="
M248.958923,330.377747 
	C249.689270,329.392365 250.384781,328.778290 251.080276,328.164215 
	C251.348526,328.391022 251.616776,328.617798 251.885025,328.844604 
	C251.113510,330.097656 250.341980,331.350677 249.312042,332.790039 
	C249.010452,332.233917 248.967270,331.491486 248.958923,330.377747 
z"/>
<path fill="#D6F7FE" opacity="1.000000" stroke="none" 
	d="
M205.141083,241.076874 
	C206.152832,242.200760 207.106064,243.717987 208.108704,245.571472 
	C207.132904,244.428543 206.107742,242.949387 205.141083,241.076874 
z"/>
<path fill="#69BDEA" opacity="1.000000" stroke="none" 
	d="
M38.319710,328.468872 
	C37.577194,328.849701 36.886040,328.924286 35.855888,329.008850 
	C35.915054,328.262268 36.313210,327.505768 36.711365,326.749237 
	C37.264603,327.220367 37.817841,327.691498 38.319710,328.468872 
z"/>
<path fill="#D6F7FE" opacity="1.000000" stroke="none" 
	d="
M165.039734,234.521515 
	C166.135605,234.278610 167.313812,234.359924 168.756836,234.677338 
	C167.721786,234.890854 166.421921,234.868301 165.039734,234.521515 
z"/>
<path fill="#65B9EB" opacity="1.000000" stroke="none" 
	d="
M247.911453,337.563660 
	C248.745865,338.225555 249.370880,339.097046 250.316742,339.970581 
	C249.859039,340.249054 249.080475,340.525482 248.301910,340.801910 
	C248.101959,339.792358 247.902008,338.782806 247.911453,337.563660 
z"/>
<path fill="#5EB6E5" opacity="1.000000" stroke="none" 
	d="
M317.956787,331.692688 
	C317.217316,332.430206 316.397247,332.920166 315.577209,333.410126 
	C315.916290,332.820221 316.255371,332.230316 316.770020,331.419617 
	C317.255829,331.280945 317.566010,331.363007 317.956787,331.692688 
z"/>
<path fill="#D6F7FE" opacity="1.000000" stroke="none" 
	d="
M197.076538,253.313812 
	C198.211273,253.472260 199.390991,253.991455 200.754425,254.776489 
	C199.665939,254.586395 198.393723,254.130478 197.076538,253.313812 
z"/>
<path fill="#D6F7FE" opacity="1.000000" stroke="none" 
	d="
M414.880890,326.799683 
	C414.179169,327.103485 413.464813,327.063568 412.392334,327.031250 
	C412.543579,324.728180 413.461823,324.405243 414.880890,326.799683 
z"/>
<path fill="#C5F1FD" opacity="1.000000" stroke="none" 
	d="
M423.470642,339.995972 
	C423.261749,339.204010 423.357300,338.331543 423.691437,337.225342 
	C423.878357,337.966217 423.826721,338.940796 423.470642,339.995972 
z"/>
<path fill="#D6F7FE" opacity="1.000000" stroke="none" 
	d="
M410.653442,333.182556 
	C410.801056,334.165344 410.708832,335.345612 410.370239,336.779633 
	C410.220459,335.815613 410.317047,334.597778 410.653442,333.182556 
z"/>
<path fill="#69BDEA" opacity="1.000000" stroke="none" 
	d="
M38.171474,332.707764 
	C38.005413,331.822998 38.035206,330.730804 38.134315,329.229065 
	C38.258194,330.046478 38.312763,331.273376 38.171474,332.707764 
z"/>
<path fill="#D6F7FE" opacity="1.000000" stroke="none" 
	d="
M304.109253,140.265717 
	C304.758820,140.731918 305.359589,141.554550 305.964874,142.695435 
	C305.333069,142.216507 304.696747,141.419327 304.109253,140.265717 
z"/>
<path fill="#D6F7FE" opacity="1.000000" stroke="none" 
	d="
M411.256805,341.376038 
	C412.398193,341.116150 413.784668,341.091827 415.582428,341.133484 
	C414.496460,341.336853 412.999146,341.474213 411.256805,341.376038 
z"/>
<path fill="#72C0EC" opacity="1.000000" stroke="none" 
	d="
M133.772308,331.475555 
	C133.557007,332.093567 133.055725,332.649323 132.311096,333.119965 
	C132.540604,332.494324 133.013458,331.953827 133.772308,331.475555 
z"/>
<path fill="#D6F7FE" opacity="1.000000" stroke="none" 
	d="
M443.043091,341.365234 
	C443.425903,341.029846 443.856567,340.985291 444.611572,340.930664 
	C444.741455,341.447601 444.547089,341.974579 444.352722,342.501556 
	C443.932098,342.219727 443.511536,341.937927 443.043091,341.365234 
z"/>
<path fill="#D6F7FE" opacity="1.000000" stroke="none" 
	d="
M247.276215,254.933380 
	C247.380997,254.303528 247.824112,253.652817 248.571625,252.930695 
	C248.455521,253.543701 248.035034,254.228119 247.276215,254.933380 
z"/>
<path fill="#D6F7FE" opacity="1.000000" stroke="none" 
	d="
M249.223480,252.869507 
	C249.289703,252.196274 249.723099,251.510742 250.446686,250.689392 
	C250.354797,251.321457 249.972733,252.089355 249.223480,252.869507 
z"/>
<path fill="#D6F7FE" opacity="1.000000" stroke="none" 
	d="
M409.627747,339.023926 
	C409.972443,339.402435 410.024841,339.835266 410.101074,340.594482 
	C409.588440,340.738434 409.051941,340.555969 408.515442,340.373474 
	C408.788788,339.941742 409.062164,339.510010 409.627747,339.023926 
z"/>
<path fill="#C5F1FD" opacity="1.000000" stroke="none" 
	d="
M239.829071,140.633835 
	C239.252243,140.888885 238.485687,140.917709 237.352417,140.827087 
	C237.870239,140.607620 238.754807,140.507614 239.829071,140.633835 
z"/>
<path fill="#C5F1FD" opacity="1.000000" stroke="none" 
	d="
M218.109085,160.799728 
	C218.251999,160.207703 218.715866,159.544128 219.473663,158.765076 
	C219.321732,159.342468 218.875870,160.035324 218.109085,160.799728 
z"/>
<path fill="#D6F7FE" opacity="1.000000" stroke="none" 
	d="
M320.205872,171.808044 
	C320.098846,171.394150 320.266724,170.819366 320.672913,170.050049 
	C320.767731,170.452728 320.624268,171.049942 320.205872,171.808044 
z"/>
<path fill="#D6F7FE" opacity="1.000000" stroke="none" 
	d="
M411.765564,327.203491 
	C411.903900,327.626556 411.767303,328.227814 411.407715,329.048126 
	C411.286713,328.638702 411.388672,328.010162 411.765564,327.203491 
z"/>
<path fill="#40B6FA" opacity="1.000000" stroke="none" 
	d="
M205.322418,256.957794 
	C204.980972,256.816406 204.955338,256.650024 204.953049,256.232300 
	C205.428421,255.928604 205.880447,255.876312 206.678833,255.867767 
	C206.562866,256.251953 206.100555,256.592377 205.322418,256.957794 
z"/>
<path fill="#D6F7FE" opacity="1.000000" stroke="none" 
	d="
M319.186157,173.809479 
	C319.091736,173.396912 319.272125,172.840561 319.699066,172.113800 
	C319.784088,172.517487 319.622528,173.091599 319.186157,173.809479 
z"/>
<path fill="#72C0EC" opacity="1.000000" stroke="none" 
	d="
M139.303009,331.840820 
	C138.983322,331.920929 138.646500,331.780731 138.193298,331.496124 
	C138.479919,331.441345 138.882889,331.530945 139.303009,331.840820 
z"/>
<path fill="#68BBE9" opacity="1.000000" stroke="none" 
	d="
M347.670654,327.233398 
	C347.508636,327.514832 347.085632,327.756592 346.411316,328.022308 
	C346.576569,327.762085 346.993103,327.477875 347.670654,327.233398 
z"/>
<path fill="#69BDEA" opacity="1.000000" stroke="none" 
	d="
M115.238342,341.044250 
	C115.197556,340.956726 115.391098,340.940552 115.486237,340.926819 
	C115.581383,340.913086 115.518951,341.161011 115.518951,341.161011 
	C115.518951,341.161011 115.279129,341.131805 115.238342,341.044250 
z"/>
<path fill="#68BBE9" opacity="1.000000" stroke="none" 
	d="
M52.897560,331.806915 
	C52.732212,331.936401 52.517948,331.825104 52.219841,331.518982 
	C52.403572,331.287231 52.641121,331.367889 52.897560,331.806915 
z"/>
<path fill="#69BDEA" opacity="1.000000" stroke="none" 
	d="
M38.365402,337.801819 
	C38.316456,337.842407 38.321613,337.790222 38.318424,337.810913 
	C38.315235,337.831635 38.414349,337.761200 38.365402,337.801819 
z"/>
<path fill="#C5F1FD" opacity="1.000000" stroke="none" 
	d="
M456.862488,331.115845 
	C456.886963,331.311310 456.664459,331.553192 456.194397,331.839386 
	C456.169708,331.643188 456.392578,331.402710 456.862488,331.115845 
z"/>
<path fill="#D6F7FE" opacity="1.000000" stroke="none" 
	d="
M300.194763,195.884796 
	C300.124420,195.680222 300.302582,195.394363 300.725464,195.021149 
	C300.794525,195.223694 300.618896,195.513611 300.194763,195.884796 
z"/>
<path fill="#D6F7FE" opacity="1.000000" stroke="none" 
	d="
M251.202454,249.892426 
	C251.106125,249.688873 251.251770,249.378479 251.641769,248.964493 
	C251.738892,249.169128 251.591660,249.477356 251.202454,249.892426 
z"/>
<path fill="#C5F1FD" opacity="1.000000" stroke="none" 
	d="
M247.947632,143.465576 
	C248.059906,143.636002 247.606110,143.650269 247.606110,143.650269 
	C247.606110,143.650269 247.250610,143.367905 247.182373,143.150452 
	C247.395737,142.971741 247.636139,143.092468 247.947632,143.465576 
z"/>
<path fill="#C5F1FD" opacity="1.000000" stroke="none" 
	d="
M224.212082,152.874084 
	C224.108002,152.673477 224.255310,152.364029 224.627106,151.917358 
	C224.722214,152.108521 224.592850,152.436890 224.212082,152.874084 
z"/>
<path fill="#C5F1FD" opacity="1.000000" stroke="none" 
	d="
M222.412659,154.946945 
	C222.178375,154.699982 222.304718,154.427109 222.818115,154.157898 
	C223.000351,154.397263 222.856384,154.650421 222.412659,154.946945 
z"/>
<path fill="#C5F1FD" opacity="1.000000" stroke="none" 
	d="
M220.289383,157.913879 
	C220.206741,157.723328 220.349838,157.426224 220.732117,157.037369 
	C220.948395,157.301254 220.796341,157.588486 220.289383,157.913879 
z"/>
<path fill="#D6F7FE" opacity="1.000000" stroke="none" 
	d="
M216.081024,256.241669 
	C216.267029,256.185181 216.540497,256.358246 216.888214,256.773132 
	C216.624252,256.941071 216.359604,256.759827 216.081024,256.241669 
z"/>
<path fill="#FEFFFF" opacity="1.000000" stroke="none" 
	d="
M271.319702,195.023758 
	C271.006592,194.274261 270.988403,193.492981 270.948303,192.320206 
	C272.407471,190.950623 273.888580,189.972534 275.700623,189.339966 
	C276.040680,190.481018 276.049774,191.276566 276.058899,192.072098 
	C276.058899,192.072098 275.994080,192.020859 275.670776,192.018524 
	C274.559723,192.667938 273.771942,193.319702 272.984161,193.971466 
	C272.527649,194.311646 272.071136,194.651810 271.319702,195.023758 
z"/>
<path fill="#FEFFFF" opacity="1.000000" stroke="none" 
	d="
M281.675903,184.945068 
	C282.006287,185.677383 282.005493,186.475601 282.018799,187.671432 
	C281.232208,188.697174 280.431549,189.325302 279.223389,189.744186 
	C278.496643,189.015900 278.177399,188.496857 277.858154,187.977814 
	C279.020325,186.988876 280.182526,185.999939 281.675903,184.945068 
z"/>
<path fill="#D6F7FE" opacity="1.000000" stroke="none" 
	d="
M277.591797,188.004974 
	C278.177399,188.496857 278.496643,189.015900 278.921265,189.820343 
	C278.244019,190.733749 277.461395,191.361755 276.368835,192.030930 
	C276.049774,191.276566 276.040680,190.481018 276.039398,189.285034 
	C276.473297,188.600433 276.899353,188.316284 277.591797,188.004974 
z"/>
<path fill="#D6F7FE" opacity="1.000000" stroke="none" 
	d="
M273.298157,193.932373 
	C273.771942,193.319702 274.559723,192.667938 275.705200,192.037964 
	C275.245941,192.670944 274.429047,193.282120 273.298157,193.932373 
z"/>
<path fill="#D6F7FE" opacity="1.000000" stroke="none" 
	d="
M269.276123,196.166809 
	C269.451935,195.818634 269.945465,195.446838 270.767090,195.079254 
	C270.594727,195.436691 270.094299,195.789932 269.276123,196.166809 
z"/>
<path fill="#D6F7FE" opacity="1.000000" stroke="none" 
	d="
M267.268616,197.137421 
	C267.250275,196.899216 267.552429,196.575882 268.193298,196.198029 
	C268.217682,196.446426 267.903381,196.749359 267.268616,197.137421 
z"/>
<path fill="#FEFFFF" opacity="1.000000" stroke="none" 
	d="
M223.734436,212.981720 
	C223.249710,213.649368 222.395020,214.316986 221.155670,214.969345 
	C221.635483,214.296616 222.499969,213.639145 223.734436,212.981720 
z"/>
<path fill="#FEFFFF" opacity="1.000000" stroke="none" 
	d="
M213.699448,215.997925 
	C213.135010,216.498184 212.219452,216.989929 211.082489,217.222076 
	C211.690170,216.638138 212.519257,216.313782 213.699448,215.997925 
z"/>
<path fill="#FEFFFF" opacity="1.000000" stroke="none" 
	d="
M188.664368,221.993134 
	C187.804611,222.492752 186.569061,222.995941 185.087250,223.229645 
	C185.990204,222.639023 187.139389,222.317871 188.664368,221.993134 
z"/>
<path fill="#FEFFFF" opacity="1.000000" stroke="none" 
	d="
M330.783997,294.345551 
	C330.274353,296.808929 329.736847,298.887848 329.199341,300.966797 
	C328.630371,300.917053 328.061371,300.867340 327.492401,300.817596 
	C327.492401,298.334595 327.492401,295.851593 327.492401,293.368591 
	C328.580322,293.566101 329.668213,293.763611 330.783997,294.345551 
z"/>
<path fill="#FEFFFF" opacity="1.000000" stroke="none" 
	d="
M277.083893,302.604706 
	C276.446747,299.123810 276.204010,295.757385 275.961304,292.390961 
	C276.674805,292.370300 277.388275,292.349640 278.101776,292.328979 
	C279.051178,295.759186 280.410736,299.177612 277.083893,302.604706 
z"/>
<path fill="#FEFFFF" opacity="1.000000" stroke="none" 
	d="
M224.083878,294.561707 
	C224.714783,293.509583 225.297958,292.868713 226.622040,291.413605 
	C226.622040,295.823151 226.622040,299.122498 226.622040,302.421844 
	C226.053085,302.506409 225.484116,302.590942 224.915161,302.675476 
	C224.622162,300.107971 224.329147,297.540497 224.083878,294.561707 
z"/>
<path fill="#FEFFFF" opacity="1.000000" stroke="none" 
	d="
M380.957275,300.560974 
	C380.064819,303.450348 378.819214,303.605286 378.109344,301.079865 
	C377.492401,298.885132 377.445129,296.487366 377.468445,294.182587 
	C377.475159,293.515869 378.594391,292.860443 379.203613,292.199860 
	C379.785461,292.844818 380.784790,293.439606 380.869476,294.144318 
	C381.106415,296.116364 380.965302,298.133820 380.957275,300.560974 
z"/>
<path fill="#68BBE9" opacity="1.000000" stroke="none" 
	d="
M52.974655,336.993103 
	C51.895115,336.122528 50.829525,335.264648 49.763931,334.406799 
	C50.297436,333.980530 50.830940,333.554230 51.364445,333.127960 
	C51.905548,334.167786 52.446655,335.207611 52.987694,336.610382 
	C52.987625,336.973328 52.988609,337.005768 52.974655,336.993103 
z"/>
<path fill="#68BBE9" opacity="1.000000" stroke="none" 
	d="
M47.027023,337.026459 
	C47.586849,337.516937 48.146679,338.007416 48.858330,338.744049 
	C49.010155,338.990204 48.984264,339.024811 48.984264,339.024811 
	C47.834244,338.946014 46.684219,338.867218 45.534195,338.788422 
	C45.813396,338.213348 46.092598,337.638306 46.685398,337.031067 
	C46.998993,336.998871 47.027023,337.026459 47.027023,337.026459 
z"/>
<path fill="#68BBE9" opacity="1.000000" stroke="none" 
	d="
M46.985153,336.984863 
	C47.136276,336.525543 47.301239,336.080261 47.466194,335.635010 
	C47.744495,335.769562 48.022800,335.904144 48.301109,336.038696 
	C48.044430,336.349426 47.787750,336.660187 47.279045,336.998718 
	C47.027023,337.026459 46.998993,336.998871 46.985153,336.984863 
z"/>
<path fill="#68BBE9" opacity="1.000000" stroke="none" 
	d="
M49.025291,338.974518 
	C49.690243,339.014069 50.340061,339.069275 50.989880,339.124512 
	C50.985153,339.281555 50.980431,339.438629 50.975708,339.595703 
	C50.428654,339.559845 49.881603,339.523987 49.159409,339.256470 
	C48.984264,339.024811 49.010155,338.990204 49.025291,338.974518 
z"/>
<path fill="#68BBE9" opacity="1.000000" stroke="none" 
	d="
M48.532295,333.548401 
	C48.477306,333.581024 48.587280,333.515808 48.532295,333.548401 
z"/>
<path fill="#E2F9FD" opacity="1.000000" stroke="none" 
	d="
M351.562408,336.863953 
	C351.562408,336.863953 351.259918,336.877808 351.130524,336.956696 
	C349.604340,337.277832 348.207520,337.520081 346.424042,337.717804 
	C346.037354,337.673279 346.064667,337.617371 346.064667,337.617371 
	C345.779358,337.216248 345.494049,336.815125 345.090607,335.829926 
	C344.973694,334.497620 344.974945,333.749359 344.976196,333.001099 
	C345.303253,332.534058 345.630341,332.067047 346.086060,331.344971 
	C347.943420,330.955322 349.672089,330.820770 351.714081,330.840393 
	C352.271851,332.087494 352.516266,333.180420 352.735596,334.614563 
	C352.710480,334.955811 352.687103,335.234406 352.489807,335.524292 
	C352.049133,336.164093 351.805786,336.514008 351.562408,336.863953 
z"/>
<path fill="#60B7E8" opacity="1.000000" stroke="none" 
	d="
M344.643524,333.048309 
	C344.974945,333.749359 344.973694,334.497620 344.960693,335.619751 
	C344.736237,335.027557 344.523529,334.061554 344.643524,333.048309 
z"/>
<path fill="#60B7E8" opacity="1.000000" stroke="none" 
	d="
M346.059631,337.695007 
	C346.453033,338.354004 346.824158,338.991241 347.195282,339.628479 
	C346.911072,339.760559 346.626892,339.892609 346.342682,340.024689 
	C346.113586,339.383667 345.884460,338.742615 345.860016,337.859497 
	C346.064667,337.617371 346.037354,337.673279 346.059631,337.695007 
z"/>
<path fill="#68BBE9" opacity="1.000000" stroke="none" 
	d="
M351.895325,336.765381 
	C351.805786,336.514008 352.049133,336.164093 352.565948,335.641510 
	C352.635681,335.868164 352.431976,336.267487 351.895325,336.765381 
z"/>
</g>
</svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
